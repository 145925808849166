import React, { Component } from 'react';
import axios from 'axios'

const AppContext = React.createContext()

class AppProvider extends Component {
  state = {
    loggedIn: sessionStorage.getItem("loggedIn"),
    user: {},
    donated_amount: null,
    amount: 0,
    symbol:'chf',
  }

  componentDidMount () {
    axios.get('user_signed_in')
    .then(response => {
      if(response.data.status == "success"){
        sessionStorage.setItem("loggedIn", "true");        
        this.setState({
          loggedIn: "true",
          user: response.data.user
        })
      }else {
        sessionStorage.removeItem('loggedIn')
      }
    })
    .catch(error => {

    })
  }

  render(){
    return(
      <AppContext.Provider value={{
        state: this.state,
        logOut: (e) => {
          e.preventDefault();
          axios.get('/sign_out')
          sessionStorage.removeItem('loggedIn')
          this.setState({
            loggedIn: false, 
            user: {}
          })
        },
        logIn: (user) => {
          this.setState({
            loggedIn: "true",
            user
          })
        },
        donateHandler: donated_amount => {
          this.setState({
            donated_amount
          })
        },
        packageHanlder: (amount) => {
          // alert(amount)
          console.log("amount",amount)
          this.setState({
            amount
          })
        } ,
        symbolHandler:(symbol)=>{
          console.log("symbol",symbol)
this.setState({symbol})
        }
      }}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

export { AppProvider, AppContext }
