import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
class Successful extends Component {
  render() {
    return (

      <div className="thank-you">
          <div className="main-container">
                <div className="thank-you__content">
                    <h1><FormattedMessage id="thank-you__content.sucessfully" defaultMessage="Thank you! You’ve sucessfully" /><br/><FormattedMessage id="thank-you__content.registered" defaultMessage="registered" /></h1>
                    <p><FormattedMessage id="thank-you__content.please.verify.login" defaultMessage="We have sent you a verification email. Please verify to login" /></p>
                </div>
          </div>
      </div>

    );
  }
}

export default Successful;
