import React, { Component } from 'react';
import Profile from './profile.js';
import { FormattedMessage } from 'react-intl';
import Slider from "react-slick";
import axios from 'axios'

class MadeA_Diff extends Component {

  state = {
    besties: []
  }

  componentDidMount(){
    axios.get(`/besties?language=${this.props.language}`)
    .then(response => {
      this.setState({
        besties: response.data
      })
    })
  }

  componentWillReceiveProps(newProps){
    axios.get(`/besties?language=${newProps.language}`)
    .then(response => {
      this.setState({
        besties: response.data
      })
    })
  }  

  render(){
    const settings = {
      dots: false,
    
      arrows: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: this.state.besties.length<4 ? this.state.besties.length :4,
     
      slidesToScroll: 1,
      responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow:  this.state.besties.length<3 ? this.state.besties.length :3,
        centerMode: true,
        centerPadding: "150px"
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: this.state.besties.length<3 ? this.state.besties.length :3,
        centerMode: true,
        centerPadding: "70px"
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: this.state.besties.length<2 ? this.state.besties.length :2,
        centerMode: true,
        centerPadding: "50px"
      }
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "50px"
      }
    },
  ]
    };

    const besties = this.state.besties.map(best => {
      return(
        <Profile image= { best.image } FirstName = {best.name} LastName = "" designation = {best.designation}/>
      )
    })

    return(
      <div className="made_a_diff-outer">
        <h3 className="heading-top-line">
          <span class="heading-topline-span"><FormattedMessage id="made.a.diff.outer.they" defaultMessage=" They " /></span><FormattedMessage id="made.a.diff.outer.made" defaultMessage=" made a difference" /> 
        </h3>
        <Slider {...settings}>
          {besties}
        </Slider>
      </div>

    );
  }
}
export default MadeA_Diff
