import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from "react-router-dom"
import axios from 'axios'

import { LazyLoadComponent } from 'react-lazy-load-image-component';

import NewsModal from './NewsModal';
import ThemeButton  from '../common_components/theme_btn'
import Slider from "react-slick";
import ImageLoader from "react-loading-image";

class NewsCategory extends Component {

  state = {
    inputValue : "" ,
    category_stories: [],
    heading: "News Category"
  }

  headings = {
    "new_stories": "New Stories",
    "top_stories": "Top Stories",
    "editors_picks": "Editors Picks"
  }

  componentDidMount(){
    const { match: { params } } = this.props;
    let url = `/stories?language=${this.props.language}`
    if(params.category !== 'new_stories'){
      url = `/stories?category=${params.category}&language=${this.props.language}`
    }
    axios.get(url)
    .then(response => {
      this.setState({
        category_stories: response.data,
        heading: params.category
      })
    }).catch(function() {
      this.setState({category_stories: []})
    });
  }

  componentWillReceiveProps(newProps){
    const { match: { params } } = this.props;
    let url = `/stories?language=${newProps.language}`
    if(params.category !== 'new_stories'){
      url = `/stories?category=${params.category}&language=${newProps.language}`
    }
    axios.get(url)
    .then(response => {
      this.setState({
        category_stories: response.data,
        heading: params.category
      })
    }).catch(function() {
      this.setState({category_stories: []})
    });
  }  

  onChangeHandler = e => {
    this.setState({inputValue : e.target.value})
  }

  enterPressed(event, link) {
    var code = event.keyCode || event.which;
    if(code === 13) {
      document.getElementById('donation-wall-search_bar').click()
    }
  }

  renderNewStories = (item, index) => {
    return (
      <LazyLoadComponent>
        <div className="new-stories">
          <div className="new-stories__image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="new-stories__content">
            <h3>{item.title}</h3>
            <p>{item.short_desc}</p>
            <NavLink to={`/news-details/${item.id}`} className="read-more__btn"><FormattedMessage id="Home.read.more" defaultMessage="read more" /></NavLink>
          </div>
        </div>
      </LazyLoadComponent>
    );
  }
  render() {
    const { match: { params } } = this.props;
    const link = `/news-category-search?keyword=${this.state.inputValue}&category=${params.category}`
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow:1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "150px",
      responsive: [
    {
      breakpoint: 991,
      settings: {
        centerPadding: "100px"
      }
    },
    {
      breakpoint: 767,
      settings: {
        centerPadding: "60px"
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerPadding: "25px"
      }
    },
  ]
    };
    return (
      <div className="news_category-landing">
      <NewsModal pageName="news_category-landing-modal" />
        <div className="news__banner">
            <h1 className="heading-top-line">{this.headings[this.state.heading]}</h1>
            <div className="donation-wall__search-bar">
              <div className="donation-wall__search-bar--content">
                <input type="text" placeholder="Search" onChange={this.onChangeHandler} onKeyPress={(e) => this.enterPressed(e, link)}/>
                <NavLink id='donation-wall-search_bar'  to={link} className="search-btn"/>
              </div>
            </div>
          </div>

          <Slider {...settings}>
              {this.state.category_stories.slice(0, 4).map(item => (
                  <div key={item.id} className="news-category-single__wrapper news-category__slider-single">
                    <div className="news-single__content">
                      <div className="img-wrapper">
                      <ImageLoader
                        src={item.image}
                        loading={() => <div className="img-loader"></div>}
                        error={() => <div className="img-loader--error"></div>}
                      />
                      </div>
                      <div className="news-single__details-wrapper news-category__slider-single-details">
                        <div className="details">
                            <h6>{item.title}</h6>
                            <NavLink to={`/news-details/${item.id}`}>
              <ThemeButton btn_text={<FormattedMessage id="Home.read.more" defaultMessage="read more" />}/>
                            </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
              ))}
          </Slider>
          <div className="main-container">
            <div className="news__landing-block">
              <h1>{this.headings[this.state.heading]}</h1>
                <div className="top-stories">
                  {this.state.category_stories.map((data, index) =>
                    this.renderNewStories(data, index)
                  )}
                </div>
            </div>
          </div>
      </div>
    );
  }
}

export default NewsCategory;
