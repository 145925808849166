import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { CardElement, injectStripe } from "react-stripe-elements";
import axios from "axios";
import { NavLink, Link } from "react-router-dom";
import SelectBox from "../common_components/selectbox";
import TextBox from "../common_components/text_box";
import LabelInputPair2column from "../common_components/label_input_pair_2column";
import ToolTip from "../common_components/tool_tip";
import Checkbox from "../common_components/checkbox";
import CardIcons from "../../assets/images/credit-cards-2.png";
import ThemeButton from "../common_components/theme_btn";

import { DatePicker } from "element-react";
// Translation to english
import { i18n } from "element-react";
import locale from "element-react/src/locale/lang/en";

import "element-theme-default";
import ThemeButtonLoad from "../common_components/theme_btn_load";

i18n.use(locale);

let scriptLoadingStarted = false;
var _this = void 0;
class paymentLeft extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anonymous: false,
      done: true,
      items: [{ value: null }],
    };
    this.buttonClick = this.buttonClick.bind(this);
    // this.response = this.response.bind(this)
  }

  buttonClick() {
    this.setState({ done: false });
  }

  // response = () =>{
  //   this.setState({done:true})
  //   alert(response.data.messages);
  // }

  handleSubmit = (e) => {
    e.preventDefault();
    this.buttonClick();
    if (this.props.stripe) {
      this.props.stripe.createToken().then(({ token, error }) => {
        if (error) {
          alert(error.message);
          console.log(error.message);
        } else {
          let data = this.formData();
          data["token"] = token.id;
          axios.post("/donate", data).then((response) => {
            var i = 0;
            console.log(this.state.done);
            if (response.data.status == "success") {
              // setTimeout(() => {
              this.setState({ done: true });
              // }, 3000);
              this.props.context.donateHandler(data["amount"]);
              this.props.history.push("payment/thankyou");
              // this.setState({done:true})
            } else {
              // setTimeout(function(){
              this.setState({ done: true });
              alert(response.data.messages);
              // }.bind(this), 3000);

              console.log("this has to be true" + this.state.done);
            }
          });
        }
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  handleBlur = () => {};
  handleChange = (change) => {};
  handleClick = () => {};
  handleFocus = () => {};
  handleReady = () => {};

  amountChangeHander = (e, name) => {
    this.props.context.packageHanlder(e.target.value);
  };
  currencyChangeHandler=(item)=>{
    this.props.context.symbolHandler(item);
  }

  formData = () => {
    var obj = {};
    var form = document.getElementById("donate_form");
    var elements = form.querySelectorAll("input, select");
    for (var i = 0; i < elements.length; ++i) {
      var element = elements[i];
      var name = element.name;
      var value = element.value;

      if (name) {
        obj[name] = value;
      }
    }

    return obj;
  };

  checkBoxHandler = () => {
    this.setState({
      anonymous: !this.state.anonymous,
    });
  };

  componentDidMount() {
    this.setState({
      items: [{ value: "CHF", id: "chf" }, { value: "USD", id: "usd" }],
    });
  }

  render() {
    const { value1, value2 } = this.state;
    return (
      <div className="payment__Left">
        <h3>
          <span>
            <FormattedMessage id="payment__Left.drink" defaultMessage="DRINK" />
          </span>
          <FormattedMessage
            id="payment__Left.AND.DONATE"
            defaultMessage="AND DONATE"
          />
        </h3>
        <p>
          <FormattedMessage
            id="payment__Left.lorem"
            defaultMessage=" Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,
           when an unknown printer took a galley of type and scrambled it to make a type
           specimen book. It has survived not only five centuries,"
          />
        </p>
        <h3>
          <span>
            <FormattedMessage
              id="payment__Left.select"
              defaultMessage="Select an"
            />
          </span>{" "}
          <FormattedMessage id="payment__Left.amount" defaultMessage="amount" />
        </h3>
        <form id="donate_form" onSubmit={this.handleSubmit}>
          <div className="select-content-outer">
            <SelectBox
              items={this.state.items}
              name="currency"
              symbolHandler={this.currencyChangeHandler}
            />
            <TextBox
              name="display-block"
              value={this.props.context.state.amount}
              onChangeHandler={this.amountChangeHander}
              name_property="amount"
              placeholder="Enter Amount"
            />
          </div>
          <div className="your-info-container">
            <h3>
              <FormattedMessage
                id="payment__Left.information"
                defaultMessage="Your information"
              />
            </h3>
            <LabelInputPair2column
              label="Name"
              name_property="first_name"
              placeholder="Full Name"
            />
            {console.log(
              <FormattedMessage
                id="payment__Left.Full.Name"
                defaultMessage="Full Name"
              />
            )}
            <div className="make-donation-outer checkbox">
              <div />
              <Checkbox
                value={this.state.anonymous}
                onClickHandler={this.checkBoxHandler}
                id="html"
                name="anonymus"
                labelname={
                  <FormattedMessage
                    id="payment__Left.make"
                    defaultMessage="Make donation anonymous"
                  />
                }
              />
              <ToolTip toot_tip_text="Lorem Ipsum" />
            </div>
            <LabelInputPair2column
              name_property="email"
              name="Email"
              label="Email"
              placeholder="abc@example.com"
            />
            <div className="reciept-msg">
              <div />
              <p>
                <FormattedMessage
                  id="payment__Left.reciept"
                  defaultMessage="Your reciept will be sent here"
                />
              </p>
            </div>
            {/*<LabelInputPair2column name_property='ph_no' name="Phone" label="Phone no."/>
            <div className="label-selectbox-pair column-2">
              <label className="label">Gender</label>
              <SelectBox
                items={[
                  {value: "Male" , id: 'male'},
                  {value: "Female" , id: 'female'},
                ]}
                name='gender'
              />
            </div>
            <div className="birthday-outer label-selectbox-pair column-4">
              <label className="label">Birthday</label>
              <div className="source">
                <div className="block">
                  <DatePicker
                    value={value1}
                    placeholder="Pick a day"
                    onChange={date=>{
                      this.setState({value1: date})
                    }}
                    disabledDate={time=>time.getTime() < Date.now() - 8.64e7}
                  />
                </div>
              </div>
            </div> */}
          </div>
          <div className="payment-details-container">
            <h3>
              <FormattedMessage
                id="payment__Left.payment"
                defaultMessage="Payment details"
              />
            </h3>
            {/* <LabelInputPair3column name_property1='card_first_name' name_property2='card_last_name' label="Name on the card" placeholder1="Frist Name" placeholder2="Last Name"/> */}
            <div className="label-input-pair-row StripeElement-card-outer column-2">
              <div className="label">
                <FormattedMessage
                  id="payment__Left.Card.Numbers"
                  defaultMessage="Card Numbers"
                />
              </div>
              <CardElement
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onReady={this.handleReady}
              />
            </div>
            <div className="card-icons-outer">
              <div />
              <div className="icon-outer">
                <img src={CardIcons} />
              </div>
            </div>
            {/*<div className="label-selectbox-pair column-2">
                <label className="label">Country</label>
                <SelectBox
                  items={[
                    {value: "Switzerland" , id: 'che'},
                    {value: "US" , id: 'us'}
                  ]}
                  name='country'
                />
              </div>
              <LabelInputPair2column name_property='city' name="short-input" label="City" placeholder=""/>
              <div className="State-zip-code label-input-pair-row column-3">
                <div className="label">State & Zip Code</div>
                  <SelectBox
                    items={[
                      {value: "Kerala" , id: 'kerala'},
                      {value: "Goa" , id: 'goa'},
                    ]}
                    name='state'
                  />
                  <TextBox placeholder="ZIP" name_property='zip_code' />
                </div> */}
            <div className="agree-terms">
              <p>
                <FormattedMessage
                  id="payment__Left.Card.by"
                  defaultMessage="By clicking Donate Now, I agree to the"
                />{" "}
                <NavLink to="/terms-conditions">
                  <FormattedMessage
                    id="terms-conditions__background"
                    defaultMessage="Terms & Conditions"
                  />
                </NavLink>
                <FormattedMessage
                  id="payment__Left.Card.and"
                  defaultMessage="and"
                />{" "}
                <NavLink to="/privacy-policy">
                  <FormattedMessage
                    id="privacy-policy__background"
                    defaultMessage="Privacy Policy"
                  />
                </NavLink>
              </p>
            </div>
            <div className="amount-submit-container">
              <div className="label">
                <FormattedMessage
                  id="payment__Left.Card.amount"
                  defaultMessage="Amount:"
                />
              </div>
              <div className="price">{this.props.context.state.amount}</div>
              {this.state.done == false ? (
                // <ThemeButtonLoad btn_text = "loading" />
                <ThemeButtonLoad />
              ) : (
                <ThemeButton
                  btn_text={
                    <FormattedMessage
                      id="payment__Left.Card.Donate.now"
                      defaultMessage="Donate now"
                    />
                  }
                />
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default injectStripe(paymentLeft);
