  import React, { Component } from 'react'
  import { FormattedMessage } from 'react-intl';
  import Drive from '../../../assets/images/drive.png'
  import Dropbox from '../../../assets/images/dropbox.png'

  import { FilePond, File, registerPlugin } from 'react-filepond';
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
  import 'filepond/dist/filepond.min.css';

  import Icon from '../../../assets/images/compresspdf.png'

  registerPlugin(FilePondPluginFileValidateType);
  class PdfToImage extends Component {
    state = {
              files: []
          }

    handleInit() {}
    render() {
      const Imgsrc= `<img src= ${Icon}/>`
      return (
          <div className="file-upload__widget">
                <div className="functionality-heading">
                    <h1><FormattedMessage id="file-upload__widget.pdf.jpg" defaultMessage="PDF to JPG" /></h1>
                  <h2><FormattedMessage id="file-upload__widget.pdf.images" defaultMessage="The best way to convert your PDF to images" /></h2>
                </div>
                <label>
                    <img src= {Icon}/>
                </label>



                <FilePond ref={ref => this.pond = ref}
                          allowMultiple={false}
                          maxFiles={1}
                          server='/api'
                          oninit={() => this.handleInit() }
                          labelIdle= '<span class="bold-txt">Drop</span> your pdf files <br><span class="or-txt"> or </span><br><span class="underline-txt"><span class="bold-txt">Upload</span>  your files </span>'
                          onupdatefiles={(fileItems) => {
                              // Set current file objects to this.state
                              this.setState({
                                  files: fileItems.map(fileItem => fileItem.file)
                              });
                          }}>

                    {/* Update current files  */}
                    {this.state.files.map(file => (
                        <File key={file} src={file} origin="local" />
                    ))}

                </FilePond>
                <div className="cloud-widget">
                <p><FormattedMessage id="file-upload__widget.upload" defaultMessage="Upload from" /></p>
                  <div className="cloud-widget__wrap">
                      <a href="#" className="cloud-widget-drive">
                        <img src={Drive} alt="cloud-widget-drive"/>
                      </a>
                      <a href="#" className="cloud-widget-dropbox">
                        <img src={Dropbox} alt="cloud-widget-dropbox"/>
                      </a>
                  </div>
                </div>
        </div>
      );
    }
  }
  export default PdfToImage;
