import React, { Component } from 'react'

class Tool_tip extends Component {
  render(){
    return(
      <span className="tool-tip">?
        <span className="tooltiptext">{ this.props.toot_tip_text }</span>
      </span>
    );
  }
}

export default Tool_tip
