import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import CdeComponent from './CDE-component';
import doc from '../../assets/images/doc.png';

class CompressDonateEnforce extends Component{
  render(){
    return(
      <div className="cde-component-outer">
        <div className="main-container">
    <CdeComponent col_img = {require('../../assets/images/zip.png')} heading={<FormattedMessage id="cde-component-outer.compress" defaultMessage="Compress your PDF"/>} content={<FormattedMessage id="cde-component-outer.Lorem" defaultMessage="Lorem IpsumLorem IpsumLorem Ipsum"/>} />
          <CdeComponent col_img = {require('../../assets/images/donation.png')} heading={<FormattedMessage id="cde-component-outer.Donate" defaultMessage="Donate for a cause"/>} content={<FormattedMessage id="cde-component-outer.Lorem.IpsumLorem" defaultMessage="Lorem IpsumLorem IpsumLorem Ipsum"/>} />
          <CdeComponent col_img = {require('../../assets/images/faucet.png')} heading={<FormattedMessage id="cde-component-outer.enforce" defaultMessage="Enforce society"/>} content={<FormattedMessage id="cde-component-outer.Lorem.IpsumLoremthree" defaultMessage="Lorem IpsumLorem IpsumLorem Ipsum"/>} />
        </div>
      </div>
    );
  }
}
export default CompressDonateEnforce;
