import React , { Component } from 'react';
import { NavLink } from "react-router-dom"
import { FormattedMessage } from 'react-intl';


import SelectBox from './SelectBoxLang';
import HOC from '../HOC/Hoc'



class ColorHeader extends Component {

  onClickHandler = (e) => {
    document.getElementById('nav').checked = false
  }

  render () {
    return (
      <div className = "top-nav colorHeader">
          <div className="main-container">
              <div className ="top-nav__logo">
                  <NavLink to="/"><h1 className="top-nav__logo-text">PDF<span>&nbsp;FLY</span></h1></NavLink>
              </div>
              <input type="checkbox" id="nav" className="hidden"/>
              <label for="nav" className="nav-open"><i></i><i></i><i></i></label>
              <div className = "top-nav__menu">
                  <ul className="top-nav__menu-list">                 
                    <li>
                      <NavLink onClick={this.onClickHandler} to="/about" activeClassName={"active"}>
                        <FormattedMessage id="Header.about" defaultMessage="About" />   
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={this.onClickHandler} to="/news" activeClassName={"active"}>
                        <FormattedMessage id="Header.news" defaultMessage="News" />
                      </NavLink></li>
                    <li>
                      <NavLink onClick={this.onClickHandler} to="/donationwall" activeClassName={"active"}>                        
                        <FormattedMessage id="Header.donations" defaultMessage="Donations" />
                      </NavLink>
                      </li>
                    <li>
                      <NavLink onClick={this.onClickHandler} to="/drink-donate" activeClassName={"active"}>                        
                        <FormattedMessage id="Header.drink_and_donate" defaultMessage="Drink & Donate" />
                      </NavLink>
                    </li>
                    {
                      this.props.context.state.loggedIn == "true" ? (
                     
                          <li className="borderlogin" >
                            <NavLink onClick={this.props.context.logOut} className="top-nav__login-btn" to="/logout">
                            <FormattedMessage id="Header.logout" defaultMessage="Log out" />
                            </NavLink>
                          {/* </div> */}
                          </li>
                      ) : (
                        // <NavLink to="/login">
                        //    {/* <div className="logincontainer"> */}
                        //   <li className="top-nav__login-btn">                            
                        //     <FormattedMessage id="Header.login" defaultMessage="Log in" />
                        //   </li>
                        //   {/* </div> */}
                        // </NavLink>
                          <li className="borderlogin">
                            <NavLink to="/login" className="top-nav__login-btn">
                            {/* <p> */}
                                <FormattedMessage id="Header.login" defaultMessage="Log in" />
                                {/* </p> */}
                            </NavLink>
                          </li>
                      )
                    }


                  <li>
                    <SelectBox
                        items={[
                          {value: "English" , id: 'en'},
                          {value: "French" , id: 'fr'},
                          {value: "Swiss German" , id: 'de'}
                        ]}
                        languageChangeHandler={this.props.changeLanguage}
                        language={this.props.language}                        
                      />
                    </li>

                  </ul>
              </div>
          </div>
      </div>
    );
  }
}
export default HOC(ColorHeader);
