import React, { Component } from 'react';
// import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { Document, Page } from 'react-pdf/dist/entry.noworker';

 
class Rotate extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
    pdf_file: null
  }


  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  }
  

  onLoadError = (error) => {
    alert('Error while loading document! ' + error.message)
  }
 
  render() {
    const { pageNumber, numPages } = this.state;
       

    let rows = [];
    for (var i = 1; i < numPages; i++) {
        rows.push(<Page pageNumber={i} key={i} width={200} className='height-canvas' rotate={90} renderAnnotationLayer={false} renderTextLayer={false} />);
    }    
 
    return (

      <div className="page-404">
        <Document
          file={this.state.pdf_file}
          onLoadSuccess={this.onDocumentLoad}
          onLoadError={this.onLoadError}
        >
          <div>
            {rows}
          </div>
        </Document>     
      </div>      
    );
  }
}

export default Rotate;