import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import axios from 'axios'
import DropboxChooser from '../../DropboxChooser/DropboxChooser'
import GoogleDriveApi from '../../GoogleDriveApi/GoogleDriveApi'


import { FilePond, File, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';

import Icon from '../../../assets/images/compresspdf.png'

registerPlugin(FilePondPluginFileValidateType);
class ImageToPdf extends Component {
  state = {
            files: []
        }

  fromDropbox = (files) => {
    this.setState({
      files: [...this.state.files, files[0].link]
    })
  }

  googlePickerHandler = (data) => {
    if(data.action === 'picked'){
      this.setState({
        files: [...this.state.files, data.docs[0].url],
        google_drive_file_name: data.docs[0].name,
        token: window.gapi.auth.getToken().access_token,
        id: data.docs[0].id
      })        
    }
  }  

  render() {
    const Imgsrc= `<img src= ${Icon}/>`
    return (
        <div className="file-upload__widget">
              <div className="functionality-heading">
                   <h1><FormattedMessage id="file-upload__widget.jpg" defaultMessage="IMAGE to PDF" /></h1>
                  <h2><FormattedMessage id="file-upload__widget.best" defaultMessage="The best web app to convert IMAGE to PDF" /></h2>
              </div>
              <label>
                  <img src= {Icon}/>
              </label>



              <FilePond ref={ref => this.pond = ref}
                        allowMultiple={false}
                        maxFiles={1}
                        server= {{
                          fetch: (fieldName) => {
                            let fileName = fieldName.split('/')
                            let url = `/download_remote_fle?url=${fieldName}`
                            if(fileName[2] === 'drive.google.com'){
                              fileName = this.state.google_drive_file_name
                              let token= this.state.token
                              url = `/download_google_drive_fle?url=${fieldName}&file_name=${fileName}&token=${token}&id=${this.state.id}`
                            }else{                                
                              let lastElement = fileName[fileName.length - 1]
                              let lastElementSplit = lastElement.split('?')
                              fileName = lastElementSplit[0]
                            }
                            axios({
                              url: url,
                              method: 'GET',
                              responseType: 'blob',
                            }).then((response) => {
                              let theBlob = response.data
                              theBlob.lastModifiedDate = new Date()
                              theBlob.name = fileName
                              this.setState({
                                files: [...this.state.files, theBlob]
                              })
                            });
                          },
                            process:(fieldName, file, metadata, load, error, progress, abort) => {
                              const formData = new FormData();
                              formData.append(fieldName, file, file.name);
                              this.setState({
                                pdf_file: file
                              })

                              const request = new XMLHttpRequest();
                              request.open('POST', '/upload_file');
                              request.upload.onprogress = (e) => {
                                progress(e.lengthComputable, e.loaded, e.total);
                              };

                              request.onload = function() {
                                if (request.status >= 200 && request.status < 300) {
                                  load(request.responseText);
                                  const result = JSON.parse(request.responseText)
                                  axios.post('/image_to_pdf.json', {
                                    file_name: result.file_name,
                                    uniq_file_name: result.uniq_file_name,
                                    size: result.size
                                  })
                                  .then(response => {
                                    if(response.data.status === 'success'){
                                      sessionStorage.setItem("uniq_file_name", response.data.uniq_file_name);
                                      sessionStorage.setItem("out_put_file_name", response.data.out_put_file_name);
                                      sessionStorage.setItem("file_name", response.data.file_name);
                                      this.props.updateCounter(100);
                                    }else {
                                      alert('something went wrong, try again')
                                      window.location.reload();
                                    }
                                  })
                                  .catch(function (error) {
                                    alert('something went wrong, try again')
                                    window.location.reload();
                                  });
                                  this.props.onUploadCompleteHandler()
                                }
                                else {
                                  // Can call the error method if something is wrong, should exit after
                                  error('oh no');
                                }
                              }.bind(this);

                              request.send(formData);
                              return {
                                abort: () => {
                                    // This function is entered if the user has tapped the cancel button
                                    request.abort();

                                    // Let FilePond know the request has been cancelled
                                    abort();
                                }
                              };
                            }
                          }}
                          acceptedFileTypes= {[
                            'image/*'
                          ]}                          
                          labelIdle= '<span class="bold-txt">Drop</span> your pdf files <br><span class="or-txt"> or </span><br><span class="underline-txt"><span class="bold-txt">Upload</span>  your files </span>'
                        onupdatefiles={(fileItems) => {
                            this.setState({
                                files: fileItems.map(fileItem => fileItem.file)
                            });
                        }}>

                  {/* Update current files  */}
                  {this.state.files.map(file => (
                      <File key={file} src={file} />
                  ))}

              </FilePond>
              <div className="cloud-widget">
              <p><FormattedMessage id="file-upload__widget.upload" defaultMessage="Upload from" /></p>
                <div className="cloud-widget__wrap">
                    <GoogleDriveApi onChange={this.googlePickerHandler} mimeTypes={['image/png', 'image/jpeg', 'image/jpg']} />
                    <DropboxChooser extensions={['.bmp', '.gif', '.jpeg', '.png', '.tiff', '.jb2', '.jp2', '.jpx', '.pbm', '.BMP', '.GIF', '.JPEG', '.PNG','.TIFF','.JB2','.JP2','.JPX','.PBM']} onSuccess={ files => { this.fromDropbox(files) } } />
                </div>
              </div>
      </div>
    );
  }
}
export default ImageToPdf;
