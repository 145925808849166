import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom'
import { Accordion, AccordionItem } from 'react-light-accordion';
const AllFunctions =() => {
    return (
      <div className="inner-page__all-functions">
          <div className="main-container">
              <h2><FormattedMessage id="inner-page__all-functions.Enhancing" defaultMessage="Enhancing pdf experiences " /></h2>
              <p><FormattedMessage id="inner-page__all-functions.lorem" defaultMessage="Lorem ipsum dolor sit" /></p>
              <div className="all-functions__category">
                <div className="all-functions__category-col">
                    <h3><FormattedMessage id="inner-page__all-functions.convert" defaultMessage="Convert & Compress" /></h3>
                    <ul>
                      <NavLink to="/compress-pdf"><li><FormattedMessage id="inner-page__all-functions.compress" defaultMessage="Compress PDF" /></li></NavLink>
                      <NavLink to="/pdf-converter"><li><FormattedMessage id="inner-page__all-functions.PDF.Converter" defaultMessage="PDF Converter" /></li></NavLink>

                    </ul>
                </div>
                <div className="all-functions__category-col">
                    <h3><FormattedMessage id="inner-page__all-functions.split" defaultMessage="Split & Merge" /></h3>
                    <ul>
                      <NavLink to="/split-pdf"><li><FormattedMessage id="inner-page__all-functions.split.pdf" defaultMessage="Split PDF" /></li></NavLink>
                      <NavLink to="/merge-pdf"><li><FormattedMessage id="inner-page__all-functions.Merge.PDF" defaultMessage="Merge PDF" /></li></NavLink>
                    </ul>
                </div>
                <div className="all-functions__category-col">
                    <h3><FormattedMessage id="inner-page__all-functions.view.edit" defaultMessage="View & Edit" /></h3>
                    <ul>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.edit.pdf" defaultMessage="Edit PDF" /></li></NavLink>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.delete.pages" defaultMessage="Delete Pages" /></li></NavLink>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.rotate.pdf" defaultMessage="Rotate PDF" /></li></NavLink>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.pdf.reader" defaultMessage="PDF Reader" /></li></NavLink>
                    </ul>
                </div>
                <div className="all-functions__category-col">
                    <h3><FormattedMessage id="inner-page__all-functions.convert.pdf" defaultMessage="Convert from PDF" /></h3>
                    <ul>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.pdf.word" defaultMessage="PDF to Word" /></li></NavLink>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.pdf.excel" defaultMessage="PDF to Excel" /></li></NavLink>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.pdf.ppt" defaultMessage="PDF to PPT" /></li></NavLink>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.pdf.jpeg" defaultMessage="PDF to JPEG" /></li></NavLink>
                    </ul>
                </div>
                <div className="all-functions__category-col">
                    <h3><FormattedMessage id="inner-page__all-functions.convert.to.pdf" defaultMessage="Convert to PDF" /></h3>
                    <ul>
                      <NavLink to="/word-to-pdf"><li><FormattedMessage id="inner-page__all-functions.word.pdf" defaultMessage="Word to PDF" /></li></NavLink>
                      <NavLink to="/excel_to_pdf"><li><FormattedMessage id="inner-page__all-functions.excel.pdf" defaultMessage="Excel to PDF" /></li></NavLink>
                      <NavLink to="/ppt-to-pdf"><li><FormattedMessage id="inner-page__all-functions.ppt.pdf" defaultMessage="PPT to PDF" /></li></NavLink>
                      <NavLink to="/image-to-pdf"><li><FormattedMessage id="inner-page__all-functions.jpeg.pdf" defaultMessage="JPEG to PDF" /></li></NavLink>
                    </ul>
                </div>
                <div className="all-functions__category-col">
                    <h3><FormattedMessage id="inner-page__all-functions.sign" defaultMessage="Sign & Security" /></h3>
                    <ul>
                    <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.eSign" defaultMessage="eSign PDF" /></li></NavLink>
                    <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.Unlock" defaultMessage="Unlock PDF" /></li></NavLink>
                    <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.Protect" defaultMessage="Protect PDF" /></li></NavLink>
                    </ul>
                </div>
              </div>
              <Accordion className="all-functions__category-mobile">

                <AccordionItem title="Convert & Compress">
                  <ul>
                    <NavLink to="/compress-pdf"><li><FormattedMessage id="inner-page__all-functions.compress" defaultMessage="Compress PDF" /></li></NavLink>
                    <NavLink to="/pdf-converter"><li><FormattedMessage id="inner-page__all-functions.PDF.Converter" defaultMessage="PDF Converter" /></li></NavLink>
                  </ul>
                </AccordionItem>

                <AccordionItem title="Split & Merge">
                  <ul>
                  <NavLink to="/split-pdf"><li><FormattedMessage id="inner-page__all-functions.split.pdf" defaultMessage="Split PDF" /></li></NavLink>
                 <NavLink to="/merge-pdf"><li><FormattedMessage id="inner-page__all-functions.Merge.PDF" defaultMessage="Merge PDF" /></li></NavLink>
                  </ul>
                </AccordionItem>

                <AccordionItem title="View & Edit">
                  <ul>
                  <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.edit.pdf" defaultMessage="Edit PDF" /></li></NavLink>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.delete.pages" defaultMessage="Delete Pages" /></li></NavLink>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.rotate.pdf" defaultMessage="Rotate PDF" /></li></NavLink>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.pdf.reader" defaultMessage="PDF Reader" /></li></NavLink>
                  </ul>
                </AccordionItem>
                <AccordionItem title="Convert from PDF">
                  <ul>
                  <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.pdf.word" defaultMessage="PDF to Word" /></li></NavLink>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.pdf.excel" defaultMessage="PDF to Excel" /></li></NavLink>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.pdf.ppt" defaultMessage="PDF to PPT" /></li></NavLink>
                      <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.pdf.jpeg" defaultMessage="PDF to JPEG" /></li></NavLink>
                  </ul>
                </AccordionItem>
                <AccordionItem title="Sign & Security">
                  <ul>
                    <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.eSign" defaultMessage="eSign PDF" /></li></NavLink>
                    <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.Unlock" defaultMessage="Unlock PDF" /></li></NavLink>
                    <NavLink to="/compress"><li><FormattedMessage id="inner-page__all-functions.Protect" defaultMessage="Protect PDF" /></li></NavLink>
                  </ul>
                </AccordionItem>
              </Accordion>
          </div>
      </div>
    );
}

export default AllFunctions;
