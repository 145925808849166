import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import loadScript from 'load-script';
import DropboxIcon from '../../assets/images/dropbox.png'

const DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js';
const SCRIPT_ID = 'dropboxjs';

let scriptLoadingStarted = false;

// read more
// https://www.dropbox.com/developers/saver
export default class DropboxSaver extends Component {

  constructor(props) {
    super(props);

    this.onChoose = this.onChoose.bind(this);
  }

  componentDidMount() {
    if (!this.isDropboxReady() && !scriptLoadingStarted) {
      scriptLoadingStarted = true;
      loadScript(DROPBOX_SDK_URL, {
        attrs : {
          id: SCRIPT_ID,
          'data-app-key': 'hyjzjg0y4zi4vke'
        }
      });
    }
  }

  isDropboxReady() {
    return !!window.Dropbox;
  }

  onChoose() {
    if (!this.isDropboxReady() || this.props.disabled) {
      return null;
    }

    const {
      url,
      success,
      progress,
      cancel,
      error,
      file_name
    } = this.props;

    window.Dropbox.save(url, file_name, {
      success,
      progress,
      cancel,
      error
    });
  }

  render() {
    return (
      <a onClick={this.onChoose} className="cloud-widget-dropbox">
        <label className="mobile"><FormattedMessage id="cloud.widget.dropbox.save" defaultMessage="Save to Dropbox" /></label>
        <img src={DropboxIcon} alt="cloud-widget-dropbox"/>
      </a>
    );
  }
}

DropboxSaver.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
  appKey: PropTypes.string.isRequired,
  success: PropTypes.func.isRequired,
  progress: PropTypes.func,
  cancel: PropTypes.func,
  error: PropTypes.func,
  extensions: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool
};

DropboxSaver.defaultProps = {
  cancel: () => {},
  progress: () => {},
  disabled: false
};
