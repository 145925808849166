import React, { Component } from 'react'

class TextBox extends Component{
  render(){
    return(
      <div className="input-text-outer">
        <div className="group">
          <input type={this.props.type} value={this.props.value} name={this.props.name_property} onChange={(e) => this.props.onChangeHandler(e,this.props.name_property)} className = {this.props.name} required/>
            <span className="highlight"></span>
            <span className="bar"></span>
          <label>{this.props.placeholder}</label>
        </div>
      </div>
    );
  }
}

export default TextBox
