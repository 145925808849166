import React , { Component } from 'react';
import axios from 'axios'
import { FormattedMessage } from 'react-intl';
import Rain from '../../assets/vid/rain.mp4'
import SingleColGrid from './single-col-banner'
import HomepageBannerSlider from './homepage-banner-slider'
import { NavLink } from 'react-router-dom'

class HomeBanner extends Component {

  state = {
    vdo: Rain,
    img: false
  }

  disableHandler = (e) => {
    e.preventDefault()
  }

  componentDidMount(){
    axios.get('/event')
    .then(response => {
      if(response.data.status === 'success'){
        this.setState({
          img: response.data.image
        })
      }
    })
    // axios.get('/vdo')
    // .then(response => {
    //   this.setState({
    //     vdo: response.data.vdo
    //   })
    // })
  }

  render(){
    return (
        <div className="home-banner-container">
            <div className="home-banner-bg">
                <div className="home-banner-bg__left">
                  {
                    this.state.img ?
                      <img src={this.state.img} />
                      :
                      null
                  }
                  
                </div>
                <div className="home-banner-bg__right">
                  <div className="vid-overlay"></div>
                  <video className="home-banner__vid" autoPlay muted loop src={this.state.vdo} />
                </div>
            </div>
            <div className="home-banner-content-wrapper">
              <div className="main-container">
                <div className="home-banner-content">
                    <div className="home-banner-content__left">
                        <div className="home-banner-content__left-content">
                              <h3 className="heading-top-line">                                
                                <FormattedMessage id="Home.Banner.title" defaultMessage="All your online PDF tools for" /> 
                                <span className="heading-topline-span">
                                  <FormattedMessage id="Home.Banner.free" defaultMessage=" free" />
                                </span>
                              </h3>
                              <div className="home-banner-content__left-grid">
                                <NavLink to="/pdf-converter">
                                  <SingleColGrid 
                                    col_txt = {<FormattedMessage id="Home.Banner.convertor" defaultMessage="PDF Converter" />}
                                    col_img = {require('../../assets/images/pdfconvert.png')}
                                  />
                                </NavLink>
                                <NavLink to="/compress-pdf">
                                  <SingleColGrid name 
                                    col_txt = {<FormattedMessage id="Home.Banner.compress" defaultMessage="Compress PDF" />} 
                                    col_img = {require('../../assets/images/compresspdf.png')}
                                  />
                                </NavLink>
                                <NavLink to="/merge-pdf">
                                  <SingleColGrid 
                                    col_txt = {<FormattedMessage id="Home.Banner.merge" defaultMessage="Merge PDF" />}
                                    col_img = {require('../../assets/images/mergetopdf.png')}
                                  />
                                </NavLink>
                                <NavLink to="/split-pdf">
                                  <SingleColGrid 
                                    col_txt = {<FormattedMessage id="Home.Banner.split" defaultMessage="Split PDF" />} 
                                    col_img = {require('../../assets/images/edit.png')}/>
                                </NavLink>
                                <a href="#" className="Functionality-not-available">

                                {/* <NavLink to="/image-to-pdf"> */}
                                  <SingleColGrid 
                                    col_txt = {<FormattedMessage id="Home.Banner.jpeg" defaultMessage="JPEG to PDF" />}
                                    col_img = {require('../../assets/images/jpgtopdf.png')}
                                  />
                                {/* </NavLink> */}
                                </a>
                                <a href="#" className="Functionality-not-available">

                                {/* <NavLink to="/excel_to_pdf"> */}
                                  <SingleColGrid name 
                                    col_txt = {<FormattedMessage id="Home.Banner.excel" defaultMessage="Excel to PDF" />} 
                                    col_img = {require('../../assets/images/exceltopdf.png')}
                                  />
                                {/* </NavLink> */}
                                </a>
                                <a href="#" className="Functionality-not-available">

                                {/* <NavLink to="/ppt-to-pdf"> */}
                                  <SingleColGrid 
                                    col_txt = {<FormattedMessage id="Home.Banner.ppt" defaultMessage="PPT to PDF" />}  
                                    col_img = {require('../../assets/images/ppttopdf.png')}
                                  />
                                {/* </NavLink> */}
                                </a>
                                <a href="#" className="Functionality-not-available">

                                {/* <NavLink to="/word-to-pdf"> */}
                                  <SingleColGrid 
                                    col_txt = {<FormattedMessage id="Home.Banner.word" defaultMessage="Word to PDF" />} 
                                    col_img = {require('../../assets/images/word_to_pdf.png')}/>
                                {/* </NavLink> */}
                                            </a>       
                                                      
                                <NavLink to="/rotate-pdf" onClick={(e) => this.disableHandler(e)} className="Functionality-not-available">
                                  <SingleColGrid 
                                    col_txt = {<FormattedMessage id="Home.Banner.rotate" defaultMessage="Rotate PDF" />} 
                                    col_img = {require('../../assets/images/rotate.png')}
                                  />
                                </NavLink>
                                <a href="#" className="Functionality-not-available">
                                  <SingleColGrid 
                                    col_txt = {<FormattedMessage id="Home.Banner.pdf_to_ppt" defaultMessage="PDF to PPT" />}  
                                    col_img = {require('../../assets/images/pdf_to_ppt.png')}
                                  />
                                </a>
                                <a href="#" className="Functionality-not-available">
                                  <SingleColGrid 
                                    name col_txt = {<FormattedMessage id="Home.Banner.pdf_to_word" defaultMessage="PDF to Word" />}   
                                    col_img = {require('../../assets/images/pdf_to_word.png')}
                                  />
                                </a>
                                <NavLink to="/pdf-image" onClick={(e) => this.disableHandler(e)} className="Functionality-not-available">
                                  <SingleColGrid 
                                    col_txt = {<FormattedMessage id="Home.Banner.pdf_to_jpeg" defaultMessage="PDF to JPEG" />}  
                                    col_img = {require('../../assets/images/pdftojpg.png')}
                                  />
                                </NavLink>
                                <a href="#" className="Functionality-not-available">
                                  <SingleColGrid 
                                    col_txt = {<FormattedMessage id="Home.Banner.pdf_to_jpeg" defaultMessage="eSign PDF" />}  
                                    col_img = {require('../../assets/images/esign.png')}
                                  />
                                </a>
                                <a href="#" className="Functionality-not-available">
                                  <SingleColGrid 
                                    col_txt = {<FormattedMessage id="Home.Banner.pdf_to_excel" defaultMessage="PDF to Excel" />}
                                    col_img = {require('../../assets/images/pdf_to_ppt.png')}
                                  />
                                </a>
                                <a href="#" className="Functionality-not-available">
                                  <SingleColGrid 
                                    col_txt = {<FormattedMessage id="Home.Banner.unlock" defaultMessage="Unlock PDF" />} 
                                    col_img = {require('../../assets/images/unlock_pdf.png')}
                                  />
                                </a>
                              </div>
                              <h2 id="nounderline" >
                                <a href="#">
                                <FormattedMessage id="Home.Banner.Download" defaultMessage="Download" />
                                </a><FormattedMessage id="Home.Banner.our" defaultMessage="  our new App," /> 
                                 <span id="underline"><FormattedMessage id="Home.Banner.Reader" defaultMessage="PDF Reader" /> </span>
                                 <FormattedMessage id="Home.Banner.desktop" defaultMessage=" for desktop." />
                                 </h2>
                        </div>
                        <div className="download_chrome">
                          <a href="#">
                            <img src = {require('../../assets/images/chrome_logo.png')}/>
                            <h2 className="sub-heading">
                              <FormattedMessage id="Home.Banner.chrome" defaultMessage="Download Google chrome & browse faster" />                              
                            </h2>
                          </a>
                        </div>
                    </div>
                    <div className="home-banner-content__right">
                        <div className="home-banner-content__right-content">
                              <HomepageBannerSlider {...this.props}/>
                        </div>
                    </div>
                </div>
              </div>
            </div>

        </div>
    );
  }
}
export default HomeBanner;
