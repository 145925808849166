import React, { Component } from 'react';
import { render } from 'react-dom';
import Tabs from 'react-responsive-tabs';
import FaqTabContent from './faq-tab-content'
import FaqTabBlock from './faq-tab-block'
import axios from 'axios'
import 'react-responsive-tabs/styles.css';

class FaqTab extends Component {

  state = {
    faqs:[]
  }

  componentDidMount(){
    axios.get('/faq_categories')
    .then(response => {
      this.setState({
        faqs: response.data,
      })
    })
  }
   getTabs() {
    return this.state.faqs.map((item, index) => ({
      title: <FaqTabBlock blocktitle={item.title} blockimg={item.image} blockcontent={item.subtitle} />,
      getContent: () => <FaqTabContent blocktitle={item.title} blockcontent={item.subtitle} qa={item.faq_questions} />,
      key: index,
      tabClassName: 'tab',
      panelClassName: 'panel'
    }));
  }

  render(){
    return(
      <div className="faq__tab-content">
      <div className="main-container">
       <Tabs showMore = {false} items={this.getTabs()} />
       </div>
       </div>
    )
  }
}


export default FaqTab;
