import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom'
import ThemeButton from "../common_components/theme_btn"
import {AppContext} from '../AppProvider/AppProvider'
import {Redirect} from 'react-router-dom'

class ResetSuccess extends Component {

  render() {
    return ( 
      <AppContext.Consumer>
      {(context) => (
        <React.Fragment>
          {context.state.loggedIn == "true" ?  <Redirect to='/' /> : (
          <div className="thank-you">
              <div className="main-container">
                  <div className="thank-you__content">
                      <h1><FormattedMessage id="payment__Left.we" defaultMessage="We have successfully reset your password. You can now login with new password." /> </h1>
                      <NavLink to="/login"><ThemeButton btn_text = "Login"/></NavLink>
                  </div>
              </div>
          </div>
          )  }

        </React.Fragment>
      )}
    </AppContext.Consumer>      
    )
  }
}

export default ResetSuccess;
