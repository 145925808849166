import React, { Component } from 'react';
import PaymentLeft from './paymentLeft'
import PaymentRight from './paymentRight'
import {Elements, StripeProvider} from 'react-stripe-elements'

class Payment extends Component{
  render(){
    return (
      <div className="payment">
        <div className="main-container">
          <div className="payment-content">
            <div>
              <StripeProvider apiKey="pk_test_RFnttl9UGXp2Ec1Welx3nuSg00fFmGM940">
                <Elements>
                  <PaymentLeft {...this.props} />
                </Elements>
              </StripeProvider>
            </div>
            <PaymentRight />
          </div>
        </div>
      </div>
    );
  }
}

export default Payment;
