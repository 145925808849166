import React , { Component } from 'react'
import TopBanner_Contact from './top-banner';
import DropUsALine from './DropUsALine';
import LocationMap from './LocationMap';
import BottomBanner from '../Functionality/BottomBanner';

class ContactUs extends Component {
  render(){
    return(
      <div className="contact-outer">
        <TopBanner_Contact/>
        <div className="main-container">
          <DropUsALine/>
          <LocationMap/>
        </div>
        <BottomBanner/>

      </div>
    );
  }
}

export default ContactUs
