import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import DonateNowBtn from "../common_components/donateNowBtn"
import Powerfull_catalyst_img from "../../assets/images/powerfull_catalyst.jpg"


class ContentBlock2 extends Component{
  render(){
    return(
      <div className="content_block2">
        <div className="img-content">
          <img src= { Powerfull_catalyst_img } />
          <div className="content-outer">
            <p>
            <FormattedMessage id="content.block2.your" defaultMessage=" Your donation is a" />
               <br/>
              <strong> <FormattedMessage id="content.block2.powerful" defaultMessage=" powerful catalyst" /></strong>
            </p>
            <DonateNowBtn/>
          </div>
        </div>
        <div className="text-content">
          <h3 className="heading-top-line">
            <span class="heading-topline-span">PDFFLY</span> <FormattedMessage id="content.block2.enhancing" defaultMessage=" Enhancing pdf experiences," /> <br/>
            <FormattedMessage id="content.block2.enforcing" defaultMessage=" Enforcing social welfare." />
          </h3>
          <p>
          <FormattedMessage id="content.block2.lorem" defaultMessage=" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries," />
          </p>
        </div>

      </div>
    );
  }
}
export default ContentBlock2
