import React, { Component } from 'react';
import { NavLink, Link } from "react-router-dom"
import axios from 'axios'
import Slider from "react-slick";
import ImageLoader from "react-loading-image";
import { FormattedMessage } from 'react-intl';
class EditorsPicks extends Component {

  state = {
    editors_picks: null
  }

  componentDidMount(){
    axios.get(`/stories?category=editors_picks&language=${this.props.language}`)
    .then(response => {
      if(typeof(response.data) == 'string'){

      }else{
        this.setState({
          editors_picks: response.data
        })
      }
    }).catch(error => {

    });
  }

  componentWillReceiveProps(newProps){
    axios.get(`/stories?category=editors_picks&language=${newProps.language}`)
    .then(response => {
      if(typeof(response.data) == 'string'){

      }else{
        this.setState({
          editors_picks: response.data
        })
      }
    }).catch(error => {

    });
  }  

  renderNewStories = (item, index) => {
    return (
        <div className="editors-picks_card">
          <div className="editors-picks_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="editors-picks_content">
            <h3>{item.title}</h3>
            <NavLink to={`/news-details/${item.id}`} className="read-more__btn"><FormattedMessage id="Home.read.more" defaultMessage="read more" /></NavLink>
          </div>
          <div className="editors-picks_overlay"></div>
        </div>
    );
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoPlay: true,
      autoplaySpeed: 1000,
      responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    },
  ]
    };
    return (
      <div className="news__landing-block">
          <h1><FormattedMessage id="news__landing-block.editors" defaultMessage="Editors Picks" /></h1>
    <NavLink to="/news/editors_picks" className="news__view-more">{<FormattedMessage id="news__landing-block.view.all" defaultMessage="view all" />}</NavLink>
          <div className="editors-picks">
            {this.state.editors_picks && this.state.editors_picks.slice(0, 4).map((data, index) =>
              this.renderNewStories(data, index)
            )}
          </div>
          <div className="editors-picks--mob">
            <Slider {...settings}>
              {this.state.editors_picks && this.state.editors_picks.slice(0, 8).map(item => (
                  <div key={item.id} className="news-single__wrapper">
                    <div className="news-single__content">
                      <div className="img-wrapper">
                      <ImageLoader
                        src={item.image}
                        loading={() => <div className="img-loader"></div>}
                        error={() => <div className="img-loader--error"></div>}
                      />
                      </div>
                      <div className="details">
                          <h6>{item.title}</h6>
                          <p>{(item.short_desc)}</p>
              <NavLink to={`/news-details/${item.id}`} className="read-more__btn">{<FormattedMessage id="Home.read.more" defaultMessage="read more" />}</NavLink>
                      </div>
                    </div>
                  </div>
              ))}
            </Slider>
          </div>
      </div>
    );
  }
}

export default EditorsPicks;
