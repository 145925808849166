import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
class InProgress extends Component {

  state = {
    percentage: 0
  }

  tick=() => {
    if(this.state.percentage < 90){
      this.setState({percentage: this.state.percentage + 2});
    } else {
      this.setState({
        percentage: 0
      })
    }
  }

  componentDidMount() {
    this.interval = setInterval(this.tick, 70);
  }
  
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render(){
    return (
      <div className="inner-page__file-upload">
        <div className="main-container">
          <div className="inner-page__process">
            <div className="progress-bar">
              <div className="progress-bar__widget">
                <div className="progress-bar__filter" style ={{ height:`${this.state.percentage}%` }}></div>
                <div className="left-mask mask"></div>
                <div className="right-mask mask"></div>
              </div>
              <p><FormattedMessage id="inner-page__file-upload.In.progress" defaultMessage="In Progress " /></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InProgress;
