import React , { Componet } from 'react';
import { NavLink } from "react-router-dom"
import { FormattedMessage } from 'react-intl';
import HollowButton from "../common_components/hollow_btn"
import Img1 from '../../assets/images/5.jpg'
import Img2 from '../../assets/images/3.jpg'
import Img3 from '../../assets/images/4.jpg'
import Img4 from '../../assets/images/6.jpg'
import Img5 from '../../assets/images/7.jpg'
import Africa from '../../assets/images/africa.jpg'
import India from '../../assets/images/india.jpg'
import Australia from '../../assets/images/australia.jpg'
import Iran from '../../assets/images/iran.jpg'
import Slider from "react-slick";

import Ausmob from '../../assets/images/ausmob.jpg'
import Indmob from '../../assets/images/indmob.jpg'
import iramob from '../../assets/images/iramob.jpg'
import Afrimob from '../../assets/images/afrimob.jpg'

var settings = {
  className: "center",
       centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 1,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
};


const BottomGrid = ( props ) => {

  return(
    <section className="homePage-bottomGrid">
        <div className="homePage-bottomGrid__grid">
            <div className="homePage-bottomGrid__grid-1">
                <div></div>
                <h3 className="heading-top-line">
                <FormattedMessage id="home.page.bottom.grid.we" defaultMessage=" We made them "/>
                   <span class="heading-topline-span"> <FormattedMessage id="home.page.bottom.grid.smile" defaultMessage=" smile"/></span>
                </h3>
                <p><FormattedMessage id="home.page.bottom.grid.content" defaultMessage=" Unlike many other countries, Switzerland has excellent drinking water. However, around 780 million people worldwide have no access to clean drinking water. According to UNICEF, over 4,000 people die each day as a result of contaminated water."/></p>
            </div>
            <div className="homePage-bottomGrid__grid-2">
                <div className="homePage-bottomGrid__img-wrapper">
                  <div>
                    <img src={Img1}/>
                  </div>
                  <div>
                    <img src={Img3}/>
                  </div>
                  <div>
                    <img src={Img2}/>
                  </div>
                  <div>
                    <img src={Img3}/>
                  </div>
                </div>
            </div>
            <div className="homePage-bottomGrid__grid-3">
              <div></div>
              <div className="homePage-bottomGrid__img-wrapper">
                <div>
                  <img src={Img4}/>
                </div>
                <div>
                  <img src={Img5}/>
                </div>
              </div>
            </div>
            <div className="homePage-bottomGrid__grid-4">
            <p><FormattedMessage id="home.page.bottom.grid.content" defaultMessage=" Unlike many other countries, Switzerland has excellent drinking water. However, around 780 million people worldwide have no access to clean drinking water. According to UNICEF, over 4,000 people die each day as a result of contaminated water."/></p>
              <div></div>
            </div>
            <div className="homePage-bottomGrid__grid-5">
              <div></div>
              <div>
                <h3 className="heading-top-line">
                <FormattedMessage id="home.page.bottom.grid.areas" defaultMessage=" Areas where the "/> <span class="heading-topline-span" ><FormattedMessage id="home.page.bottom.grid.pdf" defaultMessage=" PDF FLY"/>&nbsp;<br/><FormattedMessage id="home.page.bottom.grid.helped" defaultMessage=" helped"/></span>
                </h3>
                <NavLink to="/about"><HollowButton btn_text = { <FormattedMessage id="home.page.bottom.view.details" defaultMessage=" View details "/>} btn_link = "#"/></NavLink>
              </div>

            </div>
            <div className="homePage-bottomGrid__grid-6">
              <img src={Africa}/>
              <div className="homePage-bottomGrid__country"><FormattedMessage id="home.page.bottom.grid.south" defaultMessage=" South Africa "/></div>
            </div>
        </div>
        <div className="homePage-bottomGrid__gallery">
          
            <div className="homePage-bottomGrid__gallery-wrapper">
                <img src={Australia}/>
                <div className="homePage-bottomGrid__country"><FormattedMessage id="home.page.bottom.grid.australia" defaultMessage=" Australia"/></div>
            </div>
            <div className="homePage-bottomGrid__gallery-wrapper">
                <img src={Iran}/>
                <div className="homePage-bottomGrid__country"><FormattedMessage id="home.page.bottom.grid.iran" defaultMessage=" Iran "/></div>
            </div>
            <div className="homePage-bottomGrid__gallery-wrapper">
                <img src={India}/>
                <div className="homePage-bottomGrid__country"><FormattedMessage id="home.page.bottom.grid.india" defaultMessage=" India "/></div>
            </div>
            <div className="homePage-bottomGrid__gallery-wrapper">
                <img src={Africa}/>
                <div className="homePage-bottomGrid__country"><FormattedMessage id="home.page.bottom.grid.south.africa" defaultMessage=" South Africa "/></div>
            </div>
            
        </div>

{/* phone version slider */}
                <div className="home-mobile-slider">
                <Slider {...settings}>
            <div className="homePage-bottomGrid__gallery-wrapper1">
                <img src={Ausmob}/>
                <div className="homePage-bottomGrid__country1"><FormattedMessage id="home.page.bottom.grid.australia" defaultMessage=" Australia"/></div>
            </div>
            <div className="homePage-bottomGrid__gallery-wrapper1">
                <img src={iramob}/>
                <div className="homePage-bottomGrid__country1"><FormattedMessage id="home.page.bottom.grid.iran" defaultMessage=" Iran "/></div>
            </div>
            <div className="homePage-bottomGrid__gallery-wrapper1">
                <img src={Indmob}/>
                <div className="homePage-bottomGrid__country1"><FormattedMessage id="home.page.bottom.grid.india" defaultMessage=" India "/></div>
            </div>
            <div className="homePage-bottomGrid__gallery-wrapper1">
                <img src={Afrimob}/>
                <div className="homePage-bottomGrid__country1"><FormattedMessage id="home.page.bottom.grid.south.africa" defaultMessage=" South Africa "/></div>
            </div>
            </Slider>
        </div>


   
{/* phone version slider */}     
    </section>
  );
}

export default BottomGrid;
