import React , { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import DonateNowBtn  from '../common_components/donateNowBtn'


class NewsModal extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: true
    };

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    return (
      <div id="news-modal">


        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Example Modal"
          className={`Modal ${this.props.pageName}`}
          overlayClassName="Overlay"
        >

          <button className="close-btn" onClick={this.closeModal}>X</button>
          <div className="news-modal-content">
            <h2> <FormattedMessage id="content.block2.your" defaultMessage=" Your donation is a" /><br/><span><FormattedMessage id="content.block2.powerful" defaultMessage=" powerful catalyst" /></span></h2>
            <p><FormattedMessage id="Example.Modal.by" defaultMessage="By supporting us, you are part of a solution that reaches more people. This makes your donation go further." /></p>
            <DonateNowBtn />
          </div>

        </ReactModal>
      </div>
    );
  }
}

export default NewsModal;
