import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import FunctionalityGrid from '../home/functionalityGrid.js'

class SeamlessPdfExp extends Component {

  render(){
    return(
      <div className="seamless-exp-outer">
        <div className="main-container">
          <FunctionalityGrid/>
          <div className="content-outer">
            <h3 className="heading-top-line">
              <span class="heading-topline-span"> <FormattedMessage id="seamless.exp.outer.we" defaultMessage=" We provide" /></span> <FormattedMessage id="seamless.exp.outer.seamless" defaultMessage=" seamless " /> <br/>
              <FormattedMessage id="seamless.exp.outer.pdf" defaultMessage=" pdf experiences" />
            </h3>
            <p>
            <FormattedMessage id="seamless.exp.outer.lorem" defaultMessage=" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries," />
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default SeamlessPdfExp
