import React , { Component } from 'react';
import Slider from "react-slick";
import DonateNowBtn from "../common_components/donateNowBtn"
import axios from 'axios'
import { NavLink } from "react-router-dom"

class HomepageBannerSlider extends Component {

  state = {
    stories: []
  }

  componentDidMount(){
    axios.get(`/home-slider-stories?language=${this.props.language}`)
    .then(response => {
      this.setState({
        stories: response.data
      })
    })
  }

  componentWillReceiveProps(newProps){
    axios.get(`/home-slider-stories?language=${newProps.language}`)
    .then(response => {
      this.setState({
        stories: response.data
      })
    })
  }

  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };

    const stories = this.state.stories.map(story => {
      return (
        <div>
          <h3 className="heading-top-line">
            <NavLink className='home-slider-nav-link' to={`/news-details/${story.id}`}>
            <span>{story.title}</span></NavLink>
          </h3>
          <p>
            <NavLink className='home-slider-nav-link' to={`/news-details/${story.id}`}>
            {story.short_desc}
            </NavLink>
          </p>
          <DonateNowBtn/>
        </div>
      )
    })

    return (
      <div className="">
        <Slider {...settings}>
          {stories}
        </Slider>
      </div>
    );
  }
}

export default HomepageBannerSlider;
