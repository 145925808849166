import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import ThemeButton from "../common_components/theme_btn";
import DonateNowBtn from "../common_components/donateNowBtn";

class DrinkDonationBanner extends Component {
  render() {
    return (
      <div className="drink-donate__top-banner">
        <div className="drink-donate__top-banner-wrap">
          <h3 className="heading-top-line">
            <span class="heading-topline-bold">
              {" "}
              <FormattedMessage
                id="drink-donate__top-banner-drink"
                defaultMessage="Drink"
              />
            </span>{" "}
            <FormattedMessage
              id="drink-donate__top-banner-tap.water"
              defaultMessage="tap water."
            />
            <span class="heading-topline-bold">
              <FormattedMessage
                id="drink-donate__top-banner-donate"
                defaultMessage="Donate"
              />{" "}
            </span>
            <FormattedMessage
              id="drink-donate__top-banner-drinking"
              defaultMessage="drinking water"
            />
          </h3>
          <p>
            <FormattedMessage
              id="drink-donate__top-banner-lorem"
              defaultMessage="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has "
            />
          </p>
          <p>
            <FormattedMessage
              id="drink-donate__top-banner-been"
              defaultMessage="been the industry's standard dummy text ever since the 1500s, when an unknown. "
            />{" "}
          </p>
          <DonateNowBtn />
        </div>
      </div>
    );
  }
}

export default DrinkDonationBanner;
