import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
class TopBanner_Contact extends Component{
  render(){
    return(
      <div className="top-banner_contact__wrap">
        <h2><FormattedMessage id="top.banner.contact.contact" defaultMessage="Contact us" /></h2>
        <div className="contact-options">
          <div className="option">
            <h4><FormattedMessage id="top.banner.contact.email" defaultMessage="email" /></h4>
            <label>
              <a href="mailto:info@pdffly.com"><FormattedMessage id="top.banner.contact.id" defaultMessage="info@pdffly.com" /></a>
            </label>
          </div>
          <div className="option">
            <h4><FormattedMessage id="top.banner.contact.call" defaultMessage="call us" /></h4>
            <label>
              <a href="tel:+9104428521526">+91 044 28521526</a>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBanner_Contact
