import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, Link } from "react-router-dom"
import Sad from '../../assets/images/sad.svg'

import ImageLoader from "react-loading-image";


import { LazyLoadComponent } from 'react-lazy-load-image-component';


import axios from 'axios'
import QueryString from 'query-string'

import DonationWallBanner from '../../assets/images/donationwall-banner.jpg'

class DonationSearchPage extends Component {

  state = {
    inputValue : "" ,
    items: null
  }

  componentDidMount(){
    const parsed = QueryString.parse(this.props.location.search);
    this.setState({inputValue : parsed.keyword})
    axios.get(`/donations?keyword=${parsed.keyword}`)
    .then(response => {
      this.setState({
        items: response.data,
      })
    }).catch(function() {

    });
  }

  onChangeHandler = e => {
    this.setState({inputValue : e.target.value})
    axios.get(`/donations?keyword=${e.target.value}`)
    .then(response => {
      this.setState({
        items: response.data
      })
    }).catch(function() {

    });
  }

  renderNames = item => {
    const { inputValue } = this.state;
        return    <LazyLoadComponent>
        <div className="donation-search__result-block--single">
                <div className="donation-search__result-block--img">

                <ImageLoader
                  src={item.image}
                  loading={() => <div className="img-loader"></div>}
                  error={() => <div className="img-loader--error"></div>}
                />

                </div>
                <p><span className="name">{item.first_name}</span> <FormattedMessage id="donation.search.result.block.donated" defaultMessage="Donated " />  <span className="price">{item.amount}</span><FormattedMessage id="donation.search.result.block.drink" defaultMessage="to Drink and Donate " /> <br/> <span className="date">{item.date}</span></p>
              </div>
              </LazyLoadComponent>
  }

  render() {

    const { inputValue, items } = this.state;

    return (
      <div className="donation-search">
          <div className="donation-wall__banner">
              <img src={DonationWallBanner} alt="donation-wall__banner"/>
              <div className="donation-wall__search-bar">
                <div className="donation-wall__search-bar--content">
                  <input type="text" placeholder="Search people" value={inputValue} onChange={this.onChangeHandler}/>
                  <div  className="search-btn" > </div>
                </div>
              </div>
          </div>
          <div className="donation-search__result-block">
              <div className="main-container">
                <h3> <FormattedMessage id="donation.search.result.block.search" defaultMessage="Search result for " /> <span> { inputValue } </span> " </h3>
                <div className="donation-search__result-block-container">
                    {
                      items ? (
                        items.map( item => {
                          return this.renderNames(item)
                        })
                      ) : (
                        <div></div>
                      )
                    }
                </div>
                {
                  items ? (
                    items.length == 0 ? <div className="donation-search__not-found">
                    <img src={Sad} />
                    <h1><FormattedMessage id="donation.search.result.block.nothing" defaultMessage="Nothing Found " /></h1>
                    <p><FormattedMessage id="donation.search.result.block.sorry" defaultMessage="Sorry, but nothing matched your search terms. Please try again with some different keywords " /> </p>
                    </div> : <div></div>
                  ) : (
                    <div></div>
                  )
                }
              </div>
            </div>
      </div>

    );
  }
}

export default DonationSearchPage;
