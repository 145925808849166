import React , { Component } from 'react';



class SelectBox extends Component {


  constructor(props){
    super(props);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      showItems: false,
      selectedItem: {value: null}
    }
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }
    
    this.handleClick();
  }

  dropDown = () => {
    this.setState(prevState => ({
      showItems: !prevState.showItems,
    }))
  }

  onChangeHandler = (item) => {
    if(typeof(this.props.languageChangeHandler) === 'function' ){
      this.props.languageChangeHandler(item.id)
    }
    this.setState({
      selectedItem: item,
     showItems:true,
    })

    // if(this.state.showItems ==true){
    //   this.setState({showItems:false})
    // }
    // else{
    //   this.setState({showItems:true})
    // }
    
  }  


  handleClick() {
    if (!this.state.showItems) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      showItems: !prevState.showItems,
    }));
  }
  
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return 1;
    }
    
    this.handleClick();
  }

  // onClick={this.dropDown}

  render(){

    let language = this.props.items.filter(lang => lang.id === this.props.language)[0].value


    return(<div className="select-box--box"  ref={node => { this.node = node; }} onClick={this.handleClick} 
            id="selectboxHead">
          <div className="select-box--container">

            <div className="select-box--selected-item">
              { this.state.selectedItem.value || language}
            </div>

            <div
              className="select-box--arrow"

              >
              <span
                className={this.state.showItems ? 'select-box--arrow-up' :
                'select-box--arrow-down'} />
            </div>
          <div
            className="select-box--options--lang"
            style = {{display:this.state.showItems ? 'block' : 'none'}}>
          {
            this.props.items.map ( item => <div
              key={item.id}
              onClick={() => this.onChangeHandler(item)}
              className = {(this.state.selectedItem.value || language) === item.value ? 'selected' : ''}
              >
                { item.value }
            </div> )
          }
          </div>

          </div>

          <input
              type="hidden"
              value={ this.state.selectedItem.id || this.props.items[0].id}
              name={this.state.name}
          />

      </div>
    );
  }
}

export default SelectBox;
