import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios'
import renderHTML from 'react-render-html';
import SocialMediaShare from '../common_components/SocialMediaShare'

import DonateNowBtn  from '../common_components/donateNowBtn'
import BottomBanner from '../Functionality/BottomBanner'

class NewsDetailPage extends Component {

  state = {
    item: {
      id: null,
      image: '',
      title: '',
      story: '',
      date: ''
    }
  }

  componentDidMount(){
    const id = this.props.match.params.id
    axios.get(`/fetch_story_details?id=${id}`)
    .then(response => {
      if(response.data === null){
        this.props.history.push('/')
      }
      this.setState({
        item: response.data
      })
    })
    .catch(error => {

    })
  }

  render() {

    return (
      <div className="news-detail">
          <div className="news-detail__head">
              <div className="main-container">
                  <div className="news-detail__head-wrapper">
                  <div>
                      <h1>{this.state.item.title}</h1>
                      <p>{this.state.item.date}</p>
                  </div>
                      <DonateNowBtn />
                  </div>
              </div>
          </div>
          <img src={this.state.item.image} alt={this.state.item.title} />
          <div className="main-container">
              <div className="news-detail__wrapper">
                <p>
                  {renderHTML(this.state.item.story)}
                </p>
                <div className="news-detail__category-wrapper">
                  <h6><FormattedMessage id="Home.read.people" defaultMessage="people" /></h6>
                </div>
                <div className="news-detail__share-wrapper">
                  <SocialMediaShare />
                </div>
              </div>
          </div>
          <BottomBanner/>
      </div>
    );
  }
}
export default NewsDetailPage;
