import React , { Component } from 'react';
import ImageLoader from "react-loading-image";
import { FormattedMessage } from 'react-intl';
import axios from 'axios'

class DonationRight extends Component {

constructor(props) {
  super(props);
  this.state = {
    items: [],
  }
}

componentDidMount(){
  axios.get('/donations?limit=12')
  .then(response => {
    this.setState({
      items: response.data,
    })
  })
  .catch(error => {

  })
}
  render(){
    var { items } = this.state;

    return(
      <div className="donation__right" >
        {items.map(item => (
            <div key={item.id} className="donation_right--single-col">
              <div className="donation__right-img">
              <ImageLoader
                src={item.image}
                loading={() => <div className="img-loader"></div>}
                error={() => <div className="img-loader--error"></div>}
              />
                <div className="donation__right-img-details">
                    <p> {item.first_name}<FormattedMessage id="donation.wall.right.donated" defaultMessage=" donated "/> <span>&euro;{item.amount}</span> </p>
                </div>
              </div>
            </div>
        ))}
      </div>
    );
  }
}

export default DonationRight;
