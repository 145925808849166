import React , { Component } from 'react';


class HollowButton extends Component {
  render(){
    return(
      <button className="hollow-btn">
        {this.props.btn_text }
      </button>

    );
  }
}

export default HollowButton;
