import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom'
import ThemeButton from "../common_components/theme_btn"

import SocialMediaShare from '../common_components/SocialMediaShare'
import { Redirect } from 'react-router-dom'

class ThankYou extends Component {
  render() {
    const amount = this.props.context.state.donated_amount
    const symbol = this.props.context.state.symbol
    
    return (
        amount == null ? <Redirect to='/' /> : (
            <div className="thank-you">
                <div className="main-container">
                    <div className="thank-you__content">
        <h1><FormattedMessage id="thank-you__content.sucessfully" defaultMessage="Thank you! You’ve sucessfully" /><br/><FormattedMessage id="thank-you__content.donated" defaultMessage="donated" /> <span> </span><span>{amount}&nbsp;{symbol.toUpperCase()}</span></h1>
                        <p><FormattedMessage id="thank-you__content.Pin" defaultMessage="Pin your contribution to our donation wall" /></p>
                        <NavLink to="/signup"><ThemeButton btn_text = "Pin your drop"/></NavLink>
                    </div>
                    <div className="thank-you__share">
                        <p><span><FormattedMessage id="thank-you__content.Share" defaultMessage="Share" /></span><FormattedMessage id="thank-you__content.your" defaultMessage="your contribution details to social media and inspire your friends" /> </p>
                        <div className="thank-you__share-icons">
                            <SocialMediaShare />
                        </div>
                    </div>
                </div>
            </div> 
        ) 
    )
  }
}

export default ThankYou;
