import React , { Component } from 'react';


class ThemeButton extends Component {
  render(){
    return(
      <button className="theme-btn" onClick={this.props.onClick}>
        {this.props.btn_text }
      </button>
    );
  }
}

export default ThemeButton;
