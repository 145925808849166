import React , { Component } from 'react';
import NewsSlider from './newsSlider'
import NewsFeedGridLeft from './news-feed-grid-left'

class NewsFeed extends Component { 
  render(){    
    return(
      <section className="news-feed">
        <div className="news-feed__grid">
          <NewsFeedGridLeft {...this.props}/>
          <div className="news-feed__grid-right">
            <NewsSlider {...this.props}/>
          </div>
        </div>
      </section>
    );
  }
}

export default NewsFeed;
