  import React, { Component } from 'react'
  import { FormattedMessage } from 'react-intl';
  import axios from 'axios'
  import DropboxChooser from '../../DropboxChooser/DropboxChooser'
  import GoogleDriveApi from '../../GoogleDriveApi/GoogleDriveApi'

  import Drive from '../../../assets/images/drive.png'


  import { FilePond, File, registerPlugin } from 'react-filepond';
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
  import 'filepond/dist/filepond.min.css';

  import Icon from '../../../assets/images/compresspdf.png'

  registerPlugin(FilePondPluginFileValidateType);
  class MergePdf extends Component {
    state = {
      files: [],
      disabled: true,
      file_names: null
    }

    fromDropbox = (files) => {
      this.setState({
        files: [...this.state.files, files[0].link]
      })
    }

    googlePickerHandler = (data) => {
      if(data.action === 'picked'){
        this.setState({
          files: [...this.state.files, data.docs[0].url],
          google_drive_file_name: data.docs[0].name,
          token: window.gapi.auth.getToken().access_token,
          id: data.docs[0].id
        })        
      }
    }    

    componentDidMount(){
      sessionStorage.removeItem('file_names')
    }

    mergeHandler = () => {
      this.props.onUploadCompleteHandler()

      let now_files =  this.state.files.map(file => {
        return this.state[file.name]
      })

      axios.post('/merge_pdf', {
        file_names: now_files.join(',_,')
      })
      .then(response => {
        if(response.data.status === 'success'){
          sessionStorage.setItem("out_put_file_name", response.data.out_put_file_name);
          sessionStorage.setItem("file_name", response.data.file_name);
          this.props.updateCounterMerge(100);
        }else {
          alert('something went wrong, try again')
          window.location.reload();
        }
      })
      .catch(function (error) {
        alert('something went wrong, try again')
        window.location.reload();
      });
    }

    render() {
      return (
          <div className="file-upload__widget">
                <div className="functionality-heading">
                    <h1><FormattedMessage id="file-upload__widget.merge" defaultMessage="Merge PDF" /></h1>
                  <h2><FormattedMessage id="file-upload__widget.easiest" defaultMessage="The easiest way to" /></h2>
                </div>
                <label>
                    <img src= {Icon}/>
                </label>



                <FilePond ref={ref => this.pond = ref}
                          allowMultiple={true}
                          maxFiles={4}
                          server= {{
                            fetch: (fieldName) => {
                              let fileName = fieldName.split('/')
                              let url = `/download_remote_fle?url=${fieldName}`
                              if(fileName[2] === 'drive.google.com'){
                                fileName = this.state.google_drive_file_name
                                let token= this.state.token
                                url = `/download_google_drive_fle?url=${fieldName}&file_name=${fileName}&token=${token}&id=${this.state.id}`
                              }else{                                
                                let lastElement = fileName[fileName.length - 1]
                                let lastElementSplit = lastElement.split('?')
                                fileName = lastElementSplit[0]
                              }
                              axios({
                                url: url,
                                method: 'GET',
                                responseType: 'blob',
                              }).then((response) => {
                                let theBlob = response.data
                                theBlob.lastModifiedDate = new Date()
                                theBlob.name = fileName
                                let prev_files = this.state.files.splice(-1,1)
                                this.setState({
                                  files: [...this.state.files, theBlob]
                                })
                              });
                            },
                            process:(fieldName, file, metadata, load, error, progress, abort) => {
                              const formData = new FormData();
                              formData.append(fieldName, file, file.name);
                              if(this.state.files.length > 1){
                                this.setState({
                                  pdf_file: file,
                                  disabled: false
                                })
                              }else{
                                this.setState({
                                  pdf_file: file
                                })
                              }

                              this.props.chosenFile(file)

                              const request = new XMLHttpRequest();
                              request.open('POST', '/upload_file');
                              request.upload.onprogress = (e) => {
                                progress(e.lengthComputable, e.loaded, e.total);
                              };

                              request.onload = function() {
                                if (request.status >= 200 && request.status < 300) {
                                  load(request.responseText);
                                  const result = JSON.parse(request.responseText)
                                  this.setState({
                                    [result.file_name]: result.uniq_file_name
                                  })
                                }
                                else {
                                  error('oh no');
                                }
                              }.bind(this);

                              request.send(formData);
                              return {
                                abort: () => {
                                    // This function is entered if the user has tapped the cancel button
                                    request.abort();

                                    // Let FilePond know the request has been cancelled
                                    abort();
                                }
                              };
                            }
                          }}
                          acceptedFileTypes= {[
                            'application/pdf'
                          ]}                          
                          labelIdle= '<span class="bold-txt">Drop</span> your pdf files <br><span class="or-txt"> or </span><br><span class="underline-txt"><span class="bold-txt">Upload</span>  your files </span>'
                          onupdatefiles={(fileItems) => { 
                              this.setState({
                                files: fileItems.map(fileItem => fileItem.file)
                              });
                          }}>

                    {/* Update current files  */}
                    {this.state.files.map(file => (
                        <File key={file} src={file} />
                    ))}

                </FilePond>
                <div className="cloud-widget">
                <p><FormattedMessage id="file-upload__widget.upload" defaultMessage="Upload from" /></p>
                  <div className="cloud-widget__wrap">
                      <GoogleDriveApi onChange={this.googlePickerHandler} mimeTypes={['application/pdf']} />
                      <DropboxChooser extensions={['.pdf']} onSuccess={ files => { this.fromDropbox(files) } } />
                  </div>
                </div>
                <div>
                  <button disabled={this.state.disabled} className='merge-button' onClick={this.mergeHandler}>Merge</button>
                </div>
        </div>
      );
    }
  }
  export default MergePdf;
