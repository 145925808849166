import React, { Component } from 'react';
import DrinkDonationBanner from './drink-donation-banner'
import DrinkDonationSlider from './drink-donation-slider'
import DrinkDonationNewsFeeds from './drink-donation-news-feed'
import HOC from '../HOC/Hoc'

class DrinkDonation extends Component {
  render() {
    return (
      <div className="drink-donate">
            <DrinkDonationBanner/>
            <DrinkDonationSlider {...this.props}/>
            <DrinkDonationNewsFeeds {...this.props}/>
      </div>
    );
  }
}

export default HOC(DrinkDonation);
