import React , { Component } from 'react';
import DonationLeft from './donation_left'
import DonationRight from './donation_right'

class DonationBlock extends Component {

  render(){

    return(
      <div className="donation-container">
          <DonationLeft/>
          <DonationRight/>
      </div>
    );
    }
}

export default DonationBlock;
