import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, Link } from "react-router-dom"
import Sad from '../../assets/images/sad.svg'

import { LazyLoadComponent } from 'react-lazy-load-image-component';


import axios from 'axios'
import QueryString from 'query-string'


class FaqSearchPage extends Component {

  state = {
    inputValue : "" ,
    items: []
  }

  componentDidMount(){
    const parsed = QueryString.parse(this.props.location.search);
    this.setState({inputValue : parsed.keyword})
    axios.get(`/faq_questions?keyword=${parsed.keyword}`)
    .then(response => {
      this.setState({
        items: response.data,
      })
    }).catch(function() {

    });
  }

  onChangeHandler = e => {
    this.setState({inputValue : e.target.value})
    axios.get(`/faq_questions?keyword=${e.target.value}`)
    .then(response => {
      this.setState({
        items: response.data
      })
    }).catch(function() {

    });
  }

  renderNames = item => {
    const { inputValue } = this.state;
        return    <LazyLoadComponent>
        <div className="donation-search__result-block--single">

                <p className="name"><span className="name">{item.question}</span></p>
                  <p>{item.answer}</p>
              </div>
              </LazyLoadComponent>
  }

  render() {

    const { inputValue, items } = this.state;

    return (
      <div className="donation-search faq">
          <div className="faq__top-banner">
            <div className="faq__top-banner-wrap">
            <h2><FormattedMessage id="faq__top-banner-wrap.frequently" defaultMessage="Frequently asked" />  <span> <FormattedMessage id="faq__top-banner-wrap.questain" defaultMessage="question" /> </span></h2>
            <div className="donation-wall__search-bar">
              <div className="donation-wall__search-bar--content">
                  <input type="text" placeholder="Search questions" value={inputValue} onChange={this.onChangeHandler}/>
              <div  className="search-btn" > </div>
              </div>
            </div>
            </div>
          </div>
          <div className="donation-search__result-block">
              <div className="main-container">
                <h3> <FormattedMessage id="faq__top-banner-wrap.search" defaultMessage="Search result for " />  <span>" { inputValue } </span> " </h3>
                <div className="donation-search__result-block-container">
                    {
                      items.map( item => {
                        return this.renderNames(item)
                      })
                    }
                </div>
                {
                  items.length == 0 ? <div className="donation-search__not-found">
                  <img src={Sad} />
                  <h1> <FormattedMessage id="faq__top-banner-wrap.nothing" defaultMessage="Nothing Found" /></h1>
                  <p> <FormattedMessage id="faq__top-banner-wrap.sorry" defaultMessage="Sorry, but nothing matched your search terms. Please try again with some different keywords " /> </p>
                  </div> : <div></div>
                }
              </div>
            </div>
      </div>

    );
  }
}

export default FaqSearchPage;
