import React, { Component } from 'react'
import InProgress from './In-progres'
import TopBanner from './Top-banner'
import AllFunctions from './All-functions'
import CompressDonateEnforce from './Compress-donate-enforce'
import DrinkandDonate from './DrinkandDonate'
import BottomBanner from './BottomBanner'

import FileUploadChooser from './FileUpload-chooser'
import FileDownloadChooser from './FileDownload-router'
import SplitPDF from './SplitPDF/SplitPDF';
import MergePDF from './MergePDF/MergePDF';


class Functionality extends Component {

  state = {
    inProgress: null,
    step: 1,
    file: null
  }

  onUploadCompleteHandler = () => {
    this.setState({
      step: 2
    })
  }

  onUploadCompleteHandlerSplit = () => {
    this.setState({
      step: 4
    })
  }

  onUploadCompleteHandlerMerge = () => {
    this.setState({
      step: 5
    })
  }  


  updateCounterSplit = (dataFromChild) => {
    this.setState({inProgress: dataFromChild})

    if(dataFromChild === 100){
      this.setState({step:4})
    }
  }
  
  updateCounterMerge = (dataFromChild) => {
    this.setState({inProgress: dataFromChild})

    if(dataFromChild === 100){
      this.setState({step:5})
    }
  }  

  updateCounter = (dataFromChild) => {
    this.setState({inProgress: dataFromChild})

    if(dataFromChild === 100){
      this.setState({step:3})
    }
  }

  splitDownloadChooser = () => {
    this.setState({
      step: 3
    })    
  }

  chosenFile = (file) => {
    this.setState({
      file
    })
  }

  render() {
    return (
      <div className="inner-page">
        <TopBanner/>
        {this.state.step === 1 && <FileUploadChooser 
           onUploadCompleteHandler={this.onUploadCompleteHandler} 
           updateCounter = { this.updateCounter }
           updateCounterSplit = { this.updateCounterSplit }
           updateCounterMerge={this.updateCounterMerge}
           onUploadCompleteHandlerSplit={this.onUploadCompleteHandlerSplit}
           onUploadCompleteHandlerMerge={this.onUploadCompleteHandlerMerge}
           chosenFile = {this.chosenFile}
           />}
        {this.state.step === 2 && <InProgress callbackFromParent = { this.updateCounter } />}
        {this.state.step === 3 && <FileDownloadChooser />}
        {this.state.step === 4 && <SplitPDF onUploadCompleteHandler={this.onUploadCompleteHandler} updateCounter={this.updateCounter} splitDownloadChooser={this.splitDownloadChooser} chosenFile={this.state.file} />}
        {this.state.step === 5 && <MergePDF onUploadCompleteHandler={this.onUploadCompleteHandler} updateCounter={this.updateCounter} splitDownloadChooser={this.splitDownloadChooser} chosenFile={this.state.file} />}
        <AllFunctions/>
        <CompressDonateEnforce/>
        <DrinkandDonate/>
        <BottomBanner/>
      </div>
    );
  }
}

export default Functionality;
