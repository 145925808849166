import React, { Component } from 'react';
import SingleColGrid from './single-col-banner'
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom'


class FunctionalityGrid extends Component {

  render(){
    return(
      <div className="home-banner-content__left-grid">
        <NavLink to="/compress-pdf">
          <SingleColGrid name col_txt = {<FormattedMessage id="Home.Banner.compress" defaultMessage="Compress PDF" />} col_img = {require('../../assets/images/compresspdf.png')}/></NavLink>
        <NavLink to="/pdf-converter">
          <SingleColGrid
            col_txt = {<FormattedMessage id="Home.Banner.convertor" defaultMessage="PDF Converter" />}
            col_img = {require('../../assets/images/pdfconvert.png')}
          />
        </NavLink>
        <NavLink to="/merge-pdf">
          <SingleColGrid
            col_txt = {<FormattedMessage id="Home.Banner.merge" defaultMessage="Merge PDF" />}
            col_img = {require('../../assets/images/mergetopdf.png')}
          />
        </NavLink>
        <NavLink to="/split-pdf">
          <SingleColGrid
            col_txt = {<FormattedMessage id="Home.Banner.split" defaultMessage="Split PDF" />}
            col_img = {require('../../assets/images/edit.png')}/>
        </NavLink>
        <NavLink to="/image-to-pdf">
          <SingleColGrid
            col_txt = {<FormattedMessage id="Home.Banner.jpeg" defaultMessage="JPEG to PDF" />}
            col_img = {require('../../assets/images/jpgtopdf.png')}
          />
        </NavLink>
        <NavLink to="/excel_to_pdf">
          <SingleColGrid name
            col_txt = {<FormattedMessage id="Home.Banner.excel" defaultMessage="Excel to PDF" />}
            col_img = {require('../../assets/images/exceltopdf.png')}
          />
        </NavLink>
        <NavLink to="/ppt-to-pdf">
          <SingleColGrid
            col_txt = {<FormattedMessage id="Home.Banner.ppt" defaultMessage="PPT to PDF" />}
            col_img = {require('../../assets/images/ppttopdf.png')}
          />
        </NavLink>
        <NavLink to="/word-to-pdf">
          <SingleColGrid
            col_txt = {<FormattedMessage id="Home.Banner.word" defaultMessage="Word to PDF" />}
            col_img = {require('../../assets/images/word_to_pdf.png')}/>
        </NavLink>

        <NavLink to="/rotate-pdf" onClick={(e) => this.disableHandler(e)} className="Functionality-not-available">
          <SingleColGrid
            col_txt = {<FormattedMessage id="Home.Banner.rotate" defaultMessage="Rotate PDF" />}
            col_img = {require('../../assets/images/rotate.png')}
          />
        </NavLink>
        <a href="#" className="Functionality-not-available">
          <SingleColGrid
            col_txt = {<FormattedMessage id="Home.Banner.pdf_to_ppt" defaultMessage="PDF to PPT" />}
            col_img = {require('../../assets/images/pdf_to_ppt.png')}
          />
        </a>
        <a href="#" className="Functionality-not-available">
          <SingleColGrid
            name col_txt = {<FormattedMessage id="Home.Banner.pdf_to_word" defaultMessage="PDF to Word" />}
            col_img = {require('../../assets/images/pdf_to_word.png')}
          />
        </a>
        <NavLink to="/pdf-image" onClick={(e) => this.disableHandler(e)} className="Functionality-not-available">
          <SingleColGrid
            col_txt = {<FormattedMessage id="Home.Banner.pdf_to_jpeg" defaultMessage="PDF to JPEG" />}
            col_img = {require('../../assets/images/pdftojpg.png')}
          />
        </NavLink>
        <a href="#" className="Functionality-not-available">
          <SingleColGrid
            col_txt = {<FormattedMessage id="Home.Banner.pdf_to_jpeg" defaultMessage="eSign PDF" />}
            col_img = {require('../../assets/images/esign.png')}
          />
        </a>
        <a href="#" className="Functionality-not-available">
          <SingleColGrid
            col_txt = {<FormattedMessage id="Home.Banner.pdf_to_excel" defaultMessage="PDF to Excel" />}
            col_img = {require('../../assets/images/pdf_to_ppt.png')}
          />
        </a>
        <a href="#" className="Functionality-not-available">
          <SingleColGrid
            col_txt = {<FormattedMessage id="Home.Banner.unlock" defaultMessage="Unlock PDF" />}
            col_img = {require('../../assets/images/unlock_pdf.png')}
          />
        </a>
      </div>
    );
  }
}

  export default FunctionalityGrid
