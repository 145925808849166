import React from 'react'

import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  GooglePlusIcon,
  LinkedinIcon
} from 'react-share';

const SocialMediaShare = () => {
  const url = window.location.href
  const title="PDFFLY"
  return(
    <div className="social-share">
      <FacebookShareButton
        url={url}
        quote={title}
        className="">
        <FacebookIcon
          size={32}
          round />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={title}
        className="">
        <TwitterIcon
          size={32}
          round />
      </TwitterShareButton>
      <GooglePlusShareButton
        url={url}
        className="">
        <GooglePlusIcon
          size={32}
          round />
      </GooglePlusShareButton>
      <LinkedinShareButton
        url={url}
        title={title}
        windowWidth={750}
        windowHeight={600}
        className="">
        <LinkedinIcon
          size={32}
          round />
      </LinkedinShareButton>
    </div>
  )
}

export default SocialMediaShare
