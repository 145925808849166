import React , { Component } from 'react';


class SelectBox extends Component {


  constructor(props){
    super(props);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      showItems: false,
      selectedItem: {value: null}
    }
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }
    
    this.handleClick();
  }


  //   window.addEventListener('click', function(e){  

  //     if (document.getElementById('clickbox').contains(e.target)){

  //     }
  //     else{
  //       // this.setState(prevState => ({
  //       //   showItems: !prevState.showItems,
  //       // }))
  //      this.dropDown()
  //     }


  //    })
  //   //   if (document.getElementById('clickbox').contains(e.target)){
  //   //   alert("inside");
  //   //   } 
  //   //   else{

  //   //   }
  //   // }
  // }
        // Clicked outside the box
        // alert("outside");
       
  //  this.setState({showItems:false})


 
  componentDidMount(){

    console.log("select box component mounted");
    console.log("items are :" + this.props.items[0].value)
  }

  dropDown = () => {
    this.setState(prevState => ({
      showItems: !prevState.showItems,
    }))
  }

  onChangeHandler = (item) => {
   
    if(typeof(this.props.packageHanlder) === 'function' ){
      this.props.packageHanlder(item.id)
    }
    if(typeof(this.props.symbolHandler)==='function'){
      this.props.symbolHandler(item.id)
    }
    this.setState({
      selectedItem: item
    })
    // const symbol = this.props.context.state.amount
    // alert( this.props.context.state.amount)
  }


  handleClick() {
    if (!this.state.showItems) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      showItems: !prevState.showItems,
    }));
  }
  
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }
    
    this.handleClick();
  }

  // onClick={this.dropDown}

  render(){
    return(<div className="select-box--box" id="clickbox" ref={node => { this.node = node; }} onClick={this.handleClick}  >
          <div className="select-box--container">

            <div className="select-box--selected-item">
              { this.state.selectedItem.value || this.props.items[0].value}
            </div>

            <div
              className="select-box--arrow"

              >
              <span
                className={this.state.showItems ? 'select-box--arrow-up' :
                'select-box--arrow-down'} />
            </div>
          <div
            className="select-box--options"
            style = {{display:this.state.showItems ? 'block' : 'none'}}>
          {
            this.props.items.map ( item =>(
              
              <div
              key={item.id}
              
              onClick={() => this.onChangeHandler(item)}
              className = {this.state.selectedItem === item ? 'selected' : ''}
              >
                { item.value }
            </div> ))
          }
          </div>

          </div>

          <input
              type="hidden"
              id='donation_offer'
              value={ this.state.selectedItem.id || this.props.items[0].id}
              name={this.props.name}
          />

      </div>
    );
  }
}

export default SelectBox;
