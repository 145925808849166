import React, { Component } from 'react'

import CompressPdfDownload from './file-download/compresspdf-download'
import wordToPdfDownload from './file-download/wordToPdfDownload'
import excelToPdfDownload from './file-download/excelToPdfDownload'
import pptToPdfDownload from './file-download/pptToPdfDownload'
import imgToPdfDownload from './file-download/imgToPdfDownload'
import mergePdfDownload from './file-download/mergePdfDownload'
import PdfConvertorDownload from './file-download/PdfConvertorDownload'
import SplitPdfDownload from './file-download/SplitPdfDownload'



import { Route, Switch } from 'react-router-dom'

class FileDownloadChooser extends Component {

  render() {
    return (
      <div className="inner-page__file-download">
        <div className="main-container">
          <div className="inner-page__download-box">
          <Switch>
            <Route path="/compress-pdf" component ={CompressPdfDownload} exact />
            <Route path="/word-to-pdf" component ={wordToPdfDownload} exact />
            <Route path="/excel_to_pdf" component ={excelToPdfDownload} exact />
            <Route path="/ppt-to-pdf" component ={pptToPdfDownload} exact />
            <Route path="/image-to-pdf" component ={imgToPdfDownload} exact />
            <Route path="/merge-pdf" component ={mergePdfDownload} exact />
            <Route path="/pdf-converter" component ={PdfConvertorDownload} exact />
            <Route path="/split-pdf" component ={SplitPdfDownload} exact />
          </Switch>
        </div>
      </div>
    </div>
    );
  }
}

export default FileDownloadChooser;
