import React, { Component  } from 'react';
import { FormattedMessage } from 'react-intl';
import DonateNowBtn from "../common_components/donateNowBtn"

class BottomBanner extends Component {
  render(){
    return(
      <div className="BottomBanner-outer">
        <div className="main-container">
          <div></div>
          <div className="text-content-outer">
            <h3 className="heading-bold"><FormattedMessage id="BottomBanner-outer.your" defaultMessage="Your donation is a" /> <br/><strong><FormattedMessage id="BottomBanner-outer.powerful" defaultMessage="powerful catalyst" /></strong></h3>
            <p><FormattedMessage id="BottomBanner-outer.by" defaultMessage="By supporting us, you are part of a solution that" /><br/><FormattedMessage id="BottomBanner-outer.reaches" defaultMessage="reaches more people. This makes your donation go" /><br/><FormattedMessage id="BottomBanner-outer.further" defaultMessage="further." /> </p>
            <DonateNowBtn/>
          </div>
        </div>
      </div>
    );
  }
}

export default BottomBanner;
