import React, { Component } from 'react'
import ImgBanner from '../../assets/images/inner-page-banner.jpg'
import Adver from '../../assets/images/inner-page-ad-image.png'

const TopBanner = () => {
    return (
      <div className="inner-page__top-banner">
          <img src={ImgBanner}/>
          <div className="top-banner__ad">
            <img src={Adver}/>
          </div>
      </div>
    )
}

export default TopBanner;
