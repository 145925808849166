import React, { Component } from 'react';
import Icon from '../../../assets/images/compresspdf.png'
import Icon1 from '../../../assets/images/everypage.png'
import Icon2 from '../../../assets/images/selectpages.png'


class Options extends Component {

    state = {
        option : null
    }

    optionOneHandler = () => {
        this.setState({
            option : 1
        })
    }

    optionTwoHandler = () => {
        this.setState({
            option : 2
        })
    }

    buttonClickHandler = () => {
        this.props.choosedOption(this.state.option)
    }

    render() {
        let split_array = sessionStorage.getItem('file_name').split('.')
        split_array.pop()
        const file_name = split_array[0] + '.pdf'
        return (
            <div className="inner-page__file-upload">
                <div className="main-container">
                    <div className="split-pdf-wrapper">
                        <div className="split-pdf__info">
                            <div>
                                <img src={Icon}/>
                                <h1>{file_name}</h1>
                            </div>
                        </div>
                        <div className="split-pdf__opitions">
                            <div className="middle">
                                <label>
                                    <input type="radio" name="radio" onClick={this.optionOneHandler}/>
                                    <div className="front-end box">
                                        <div className="box__wrapper">
                                            <img src={Icon1}/>
                                            <span>Extract every page into a PDF</span>
                                        </div>
                                    </div>
                                </label>
                                <label>
                                    <input type="radio" name="radio" onClick={this.optionTwoHandler}/>
                                    <div className="back-end box">
                                        <div className="box__wrapper">
                                                <img src={Icon2}/>
                                                <span>Select pages to extract</span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div className="split-pdf__btn-set">
                                <button onClick = {this.buttonClickHandler}>Choose option</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Options;
