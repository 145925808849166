import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios'
import {AppContext} from '../AppProvider/AppProvider'

import ThemeButton from "../common_components/theme_btn"
import HollowButton from "../common_components/hollow_btn"

import FbLogo from "../../assets/images/fb-logo.png"
import GoogleLogo from "../../assets/images/google-logo.png"
import SignUpIcon from "../../assets/images/img-upload.png"
import Error from "../../assets/images/close.svg"

import {NavLink, Redirect} from 'react-router-dom'

import Dropzone from 'react-dropzone'
import ReactCrop from 'react-image-crop'
import ThemeButtonLoad from '../common_components/theme_btn_load';

const imageMaxSize = 10000000 // bytes
const acceptedFileTypes = 'image/x-png ,image/png ,image/jpeg ,image/jpg ,image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})

class SignUp extends Component {

  state = {
    imgSrc: null,
    currentFile: null,
    crop: {
      aspect: 1/1
    },
    email: '',
    password: '',
    password_confirmation: '',
    signedUp: false,
    validationMsg: [],
    done:true
  }

  verifyFiles = (files) => {
    if(files && files.length > 0){
      const currentFile = files[0]
      const currentFileType = currentFile.type
      const currentFileSize = currentFile.size
      if(currentFileSize > imageMaxSize){
        alert("The file you choose is " + currentFileSize + " of size its way too big" )
        return false
      }

      if(!acceptedFileTypesArray.includes(currentFileType)){
        alert("The file you choose is " + currentFileType + " not allowed" )
        return false
      }
      return true
    }
  }

  handleOnDrop = (files , rejectedFiles) => {
    if(rejectedFiles && rejectedFiles.length > 0){
      this.verifyFiles(rejectedFiles)
    }

    if(files && files.length > 0){
      const isVerified = this.verifyFiles(files)
      if(isVerified){
          const currentFile = files[0]
          const reader = new FileReader()
          reader.addEventListener("load",()=>{
            this.setState({
              imgSrc: reader.result,
              currentFile
            })
          },false)
          reader.readAsDataURL(currentFile)
      }
    }
  }

  handleOnCropChange = (crop) => {
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e) => {

    e.preventDefault();
    this.setState({done:false})
    let formData = new FormData()
    formData.append('image', this.state.currentFile)
    formData.append('email', this.state.email)
    formData.append('password', this.state.password)
    formData.append('password_confirmation', this.state.password_confirmation)
    axios.post('/users.json', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      if(response.data.status === 'success'){
        this.props.history.push("/complete-registration");
      }else {
        var str = response.data.messages
        var str_array = str.split(',');
        this.setState({validationMsg:str_array})
        document.getElementById("validation-error").className = "validation-error-active"
      }
    })
  }


  render() {
    const {imgSrc} = this.state
    return (

      <AppContext.Consumer>
        {(context) => (
          <React.Fragment>
            {context.state.loggedIn === "true" ?  <Redirect to='/' /> : (
              <div className="sign-up">
              <div className="login">
                  <div className="main-container">
                      <div className="login__box">
                        <div className="login__box-left">
                            <h3><FormattedMessage id="login__box-btn-set.Sign.up" defaultMessage="Sign up" /></h3>
                            <div className="login__box-btn-set">
                                <a href='/users/auth/facebook'> <img src={FbLogo}/> <p><FormattedMessage id="login__box-btn-set.Sign.up.with.Facebook" defaultMessage="Sign up with Facebook" /></p></a>
                                <a href='/users/auth/google_oauth2'> <img src={GoogleLogo}/> <p><FormattedMessage id="login__box-btn-set.Sign.up.with.Google" defaultMessage="Sign up with Google" /></p></a>
                            </div>
                          <div className="login__box-or-txt">or</div>
                          <div className="validation-error" id="validation-error">
                            <div className="validation__required">
                                <img src={Error} alt="error"/>
                                <ul>
                                {this.state.validationMsg.map(function(name, index){
                                return <li key={ index }>{name}</li>;
                                })}
                                </ul>
                            </div>
                          </div>
                          <div className="form-styles">
                            <form action="" method="post" onSubmit={this.handleSubmit}>
                              <div className="sign-up__img-upload">
                                  <div className="icon">
                                    {imgSrc !== null ?
                                      <div className="img-preview">
                                        <Dropzone className="icon_null" onDrop={this.handleOnDrop} maxSize={imageMaxSize} multiple={false} accept={acceptedFileTypes}>
                                        <img src={SignUpIcon} alt="sign-up_img-upload-icon"/>
                                        </Dropzone>
                                        <ReactCrop src={imgSrc} crop={this.state.crop} onChange={this.handleOnCropChange} />
                                      </div>
                                      :
                                      <Dropzone className="icon_null" onDrop={this.handleOnDrop} maxSize={imageMaxSize} multiple={false} accept={acceptedFileTypes}>
                                      <img src={SignUpIcon} alt="sign-up_img-upload-icon"/>
                                      </Dropzone>
                                    }
                                  </div>
                                  <p className="login__box-forget-password"><FormattedMessage id="login__box-btn-set.profile.picture" defaultMessage="Add your profile picture" /></p>
                              </div>
                              <div className="group">
                                <input autoComplete="off" className="inputMaterial" onChange={this.onChangeHandler} name='email' type="text" required/>
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                <label><FormattedMessage id="login__box-btn-set.Email" defaultMessage="Email" /></label>
                              </div>
                              <div className="group">
                                <input autoComplete="off" className="inputMaterial" onChange={this.onChangeHandler} name='password' type="password" required/>
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                <label><FormattedMessage id="login__box-btn-set.Password" defaultMessage="Password" /></label>
                              </div>
                              <div className="group">
                                <input autoComplete="off" className="inputMaterial" onChange={this.onChangeHandler} name='password_confirmation' type="password" required/>
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                <label><FormattedMessage id="login__box-btn-set.confirm.Password" defaultMessage="Confirm password" /></label>
                              </div>
                                
                                  {this.state.done?  <ThemeButton btn_text = {<FormattedMessage id="login__box-btn-set.Sign.up" defaultMessage="Sign up" />} btn_link = "#"/>

                               :<ThemeButtonLoad/> }
                            </form>
                          </div>
                        </div>
                        <div className="login__box-right">
                          <div className="login__box-right-content">
                                <h2><FormattedMessage id="login__box-btn-set.confirm.Already" defaultMessage="Already a user?" />  <span id="bold"><FormattedMessage id="login__box-btn-set.confirm.log" defaultMessage="Log in" /></span><FormattedMessage id="login__box-btn-set.confirm.to.pdf" defaultMessage="to PDF FLY" /> </h2>
                              <NavLink to="/login"><HollowButton btn_text = {<FormattedMessage id="login__box-btn-set.confirm.log" defaultMessage="Log in" />}/></NavLink>
                             
                          </div>
                        </div>
                      </div>
                  </div>
                  </div>
            </div>
            )  }

          </React.Fragment>
        )}
      </AppContext.Consumer>



    );
  }
}

export default SignUp;
