import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import WaterDrop from '../../assets/images/syria-refugee.png';

class DrinkandDonate extends Component {
  render(){
    return(
      <div className="DnD-outer">
        <div className="main-container">
        <div className="DnD-right">
          <img src={ WaterDrop }/>
        </div>
          <div className="DnD-left">
            <h3 className="heading-top-line"> <span className="heading-topline-span"> <FormattedMessage id="news.feed.grid.left.drink" defaultMessage="DRINK & DONATE" /> </span><FormattedMessage id="news.feed.grid.left.Water" defaultMessage="Water" /><br/><FormattedMessage id="news.feed.grid.left.dolosit" defaultMessage="Lorem ipsum dolorsit" /></h3>
            <p>
            <FormattedMessage id="news.feed.grid.left.schwiizerwasser" defaultMessage="The schwiizerwasser and züriwasser tastes very good and can be drunk anytime from the tap. In terms of health, they are at least as good as most mineral waters. On the other hand, Hahnenwasser is unbeatably cheap in price: water from the supermarket costs around 1,000 times more than the water supply, which is also delivered to the house. From the Swiss lines flawless drinking water flows, which is available at any time and should also be used consciously as drinking" />
            </p>
          </div>

        </div>
      </div>
    );
  }
}
export default DrinkandDonate;
