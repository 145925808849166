import React , { Component } from 'react';
import ReactLoading from 'react-loading';
import loading from '../../assets/images/loading.gif'
class ThemeButtonLoad extends Component {
  render(){
    return(
      // <button className="theme-btn">
      <div className="btnload">
        
        {/* <ReactLoading type="spinningBubbles" color="#ef4800" height={'20%'} width={'20%'} /> */}
        <img src={loading}/>

      </div>
      /* </button> */
    );
  }
}

export default ThemeButtonLoad;
