import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ImgBanner from '../assets/images/privacy-policy-banner.jpg'
import axios from 'axios'
import renderHTML from 'react-render-html';

class PrivacyPolicy  extends Component {
    state = {
        terms: ''
    }

    componentDidMount(){
        axios.get('/terms')
        .then(response => {
            this.setState({
                terms: response.data.description
            })
        })
    }    

    render(){
        return (
            <div className="terms-conditions">
              <div className="terms-conditions__background">
                  <img src={ImgBanner}/>
                </div>
                    <div className="terms-conditions__container main-container">
                    <h3 className="heading-top-line"><FormattedMessage id="terms-conditions__background" defaultMessage="Terms and Conditions" /></h3>
                        <div className="terms-conditions__content">
                      <p>
                        {renderHTML(this.state.terms)}
                      </p>
                </div>
                </div>
            </div>
          )
    }
}

export default PrivacyPolicy;
