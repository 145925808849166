import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from "react-router-dom";
class FAQBanner extends Component {
  state = {
    inputValue : ""
  }

  onChangeHandler = e => {
    this.setState({inputValue : e.target.value})
  }

  enterPressed(event, link) {
    var code = event.keyCode || event.which;
    if(code === 13) {
      document.getElementById('donation-wall-search_bar').click()
    }
  }
  render() {
      const link = `/faqSearchpage?keyword=${this.state.inputValue}`
    return (
      <div className="faq__top-banner">
        <div className="faq__top-banner-wrap">
        <h2><FormattedMessage id="faq__top-banner-wrap.frequently" defaultMessage="Frequently asked" />  <span> <FormattedMessage id="faq__top-banner-wrap.questain" defaultMessage="question" /> </span></h2>
        <div className="donation-wall__search-bar">
          <div className="donation-wall__search-bar--content">
            <input type="text" placeholder="Search questions" onChange={this.onChangeHandler} onKeyPress={(e) => this.enterPressed(e, link)}/>
            <NavLink id='donation-wall-search_bar'  to={link} className="search-btn"/>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default FAQBanner;
