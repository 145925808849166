import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios'

import ThemeButton from "../common_components/theme_btn"
import HollowButton from "../common_components/hollow_btn"
import {AppContext} from '../AppProvider/AppProvider'
import HOC from '../HOC/Hoc'

import FbLogo from "../../assets/images/fb-logo.png"
import GoogleLogo from "../../assets/images/google-logo.png"
import Error from "../../assets/images/close.svg"

import { NavLink, Redirect } from "react-router-dom"
import ThemeButtonLoad from '../common_components/theme_btn_load';

class LogIn extends Component {

  state = {
    email: '',
    password: '',
    validation: '',
    error: 'Invalid username/password',
    done:true
  }

  buttonClick(){

  
    this.setState({done:false});

  }


  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  keyDownHandler = e => {
    if(e.keyCode == 13){
      this.loginHandler()
    }
  }

  loginHandler = () => {
    this.setState({done:false})
    axios.post('/users/sign_in.json', {
      user: {
        email: this.state.email,
        password: this.state.password
      }
    })
    .then(response => {
      const user = response.data
      if(response.data.status == 'neet_to_confirm'){
        document.getElementById("validation-error").className = "validation-error-active"
        this.setState({
          error: response.data.message
        })
        this.setState({done:true})
        console.log("loading button state" + this.state.done)
      }else{
        this.props.context.logIn(user)
      }
    })
    .catch( (error) => {
      this.setState({done:true});
      document.getElementById("validation-error").className = "validation-error-active";
    });
  }

  render() {
    return (
      <AppContext.Consumer>
        {(context) => (
          <React.Fragment>
            {context.state.loggedIn == "true" ?  <Redirect to='/' /> : (
            <div className="login">
            <div className="main-container">
                <div className="login__box">
                  <div className="login__box-left">
                      <h3><FormattedMessage id="login__box-btn-set.confirm.log" defaultMessage="Log in" /></h3>
                      <div className="login__box-btn-set">
                          <a href='/users/auth/facebook' > <img src={FbLogo}/> <p><FormattedMessage id="login__box-btn-set.Facebook" defaultMessage="Log in with Facebook" /></p></a>
                          <a href='/users/auth/google_oauth2'> <img src={GoogleLogo}/> <p><FormattedMessage id="login__box-btn-set.Google" defaultMessage="Log in with Google" /></p></a>
                      </div>
                    <div className="login__box-or-txt">or</div>
                    <div className="validation-error" id="validation-error">
                      <div className="validation__required">
                          <img src={Error} alt="error"/>
                          <p>{this.state.error}</p>
                      </div>
                    </div>
                    <div className="form-styles">
                        <div className="group">
                          <input className="inputMaterial" name='email' autoComplete="off" onChange={(e) => this.onChangeHandler(e)} type="text" required/>
                            <span className="highlight"></span>
                            <span className="bar"></span>
                          <label><FormattedMessage id="login__box-btn-set.Email" defaultMessage="Email" /></label>
                        </div>
                        <div className="group">
                          <input className="inputMaterial" onKeyDown={this.keyDownHandler} type="password" autoComplete="off"  name='password' onChange={(e) => this.onChangeHandler(e)} required/>
                            <span className="highlight"></span>
                            <span className="bar"></span>
                          <label><FormattedMessage id="login__box-btn-set.Password" defaultMessage="Password" /></label>
                        </div>
                        {
                    this.state.done==false?
                   // <ThemeButtonLoad btn_text = "loading" />
                   <ThemeButtonLoad/>
                     :
            <ThemeButton onClick={this.loginHandler} btn_text = {<FormattedMessage id="login__box-btn-set.confirm.log" defaultMessage="Log in" />}/>
                        }
                    </div>
                    <NavLink to="/password-new" className="login__box-forget-password" ><FormattedMessage id="login__box-btn-set.Forgot" defaultMessage="Forgot password ?" /></NavLink>                    
                  </div>
                  <div className="login__box-right">
                    <div className="login__box-right-content">
                          <h1><FormattedMessage id="login__box-btn-se.New" defaultMessage="New to" /> <span id="bold"><FormattedMessage id="login__box-btn-set.pdf" defaultMessage="PDF FLY?" /></span></h1>
                          <h2><span id="bold"><FormattedMessage id="login__box-btn-set.Sign.up" defaultMessage="Sign up" /></span> <FormattedMessage id="login__box-btn-set.to.join.the.cause" defaultMessage="to join the cause" /></h2>
            <NavLink to="/signup"><HollowButton btn_text = {<FormattedMessage id="login__box-btn-set.get" defaultMessage="Get started" />} btn_link = "#"/></NavLink>
                    </div>
                  </div>
                </div>
            </div>
            </div>
            )  }

          </React.Fragment>
        )}
      </AppContext.Consumer>
    );
  }
}

export default HOC(LogIn)
