import React, { Component } from 'react';

import axios from 'axios'
import QueryString from 'query-string'

import { NavLink } from 'react-router-dom'
import ThemeButton from "../common_components/theme_btn"

class Subscription extends Component {
  state = {
    message: ''
  }


  componentDidMount(){
    const parsed = QueryString.parse(this.props.location.search);
    this.setState({inputValue : parsed.token})    
    axios.get(`/subscription_status?token=${parsed.token}`)
    .then(response => {
      this.setState({
        message: response.data.messages.join(','),
      })
    }).catch(function() {

    });
  }  


  render() {
    return (
      <div className="thank-you">
          <div className="main-container">
              <div className="thank-you__content">
                  <h1> {this.state.message} </h1>
                  <NavLink to="/payment"><ThemeButton btn_text = "Donate a drop"/></NavLink>
              </div>
          </div>
      </div> 
    )
  }
}

export default Subscription;
