import React, { Component } from 'react';


class CdeComponent extends Component {
    render(){
      return(
        <div className="component-grid">
          <div className="img-outer">
            <img alt='' src={ this.props.col_img }/>
          </div>
          <h2>{ this.props.heading }</h2>
          <p>{ this.props.content } </p>
        </div>
      );
    }
}

export default CdeComponent;
