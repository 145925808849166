import React, { Component } from 'react'
import GooglePicker from 'react-google-picker'
import Drive from '../../assets/images/drive.png'

const GoogleDriveApi = (props) => {
  return(
    <a className="cloud-widget-drive">
    <GooglePicker clientId={'39493899007-v02ukpbhld8t5b6r8a0rqfdvctab0ib4.apps.googleusercontent.com'}
                  developerKey={'AIzaSyDZq7g4U3USVaTaEdS1yDmPXvIRpptJTn4'}
                  scope={['https://www.googleapis.com/auth/drive.readonly']}
                  onChange={data => props.onChange(data)}
                  onAuthFailed={data => console.log('on auth failed:', data)}
                  multiselect={false}
                  navHidden={true}
                  authImmediate={false}
                  mimeTypes={props.mimeTypes}
                  viewId={'DOCS'}>

        <img src={Drive} alt="cloud-widget-drive"/>

    </GooglePicker>
    </a>
  )
}

export default GoogleDriveApi
