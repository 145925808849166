import React , { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import TextBox from '../common_components/TextBox';
import HollowButton from '../common_components/hollow_btn';
import { ReCaptcha } from 'react-recaptcha-google'

class DropUsALine extends Component {

  constructor(props, context) {
    super(props, context);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount() {
    if (this.captchaDemo) {
        console.log("started, just a second...")
        this.captchaDemo.reset();
    }
  }
  onLoadRecaptcha() {
      if (this.captchaDemo) {
          this.captchaDemo.reset();
      }
  }
  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!  
    
    console.log(recaptchaToken, "<= your recaptcha token")

    if(recaptchaToken != ""){
      this.setState({captcha:true});
    } 
  }

  state = {
    name: null,
    email: null,
    mob: null,
    matter: null,
    status: null,
    message: false,
    captcha:false,
    errors: {}
  }

  fieldChangeHandler = (e, name) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  submitHandler = (e) => {
    e.preventDefault()
    if(this.validateForm()) {
      axios.post('/contact_mail',this.state)
      .then(response => {
        this.setState({
          status: response.data.messages.join(','),
          message:true
        })
      })
    }
  }

  // Validation for form.
  validateForm() {

    let errors = {};
    let formIsValid = true;

    let {name, email, mob, matter, captcha} = this.state;


    if (!name) {
      formIsValid = false;
      errors["name"] = "Please enter your name.";
    }

    if (typeof name !== "undefined") {
      if (!name.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["name"] = "Please enter valid name.";
      }
    }

    if (!email) {
      formIsValid = false;
      errors["emailid"] = "Please enter your emailid.";
    }

    if (typeof email !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(email)) {
        formIsValid = false;
        errors["emailid"] = "Please enter valid emailid.";
      }
    }

    if (!mob) {
      formIsValid = false;
      errors["mobileno"] = "Please enter your mobile no.";
    }

    if (typeof mob !== "undefined") {
      if (!mob.match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["mobileno"] = "Please enter valid mobile no.";
      }
    }
if(!captcha){
  formIsValid = false;
  errors["captchaerror"] = "Please verify captcha.";
}
    if (!matter) {
      formIsValid = false;
      errors["matter"] = "Please enter the matter.";
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  render(){
    return(
      <div className="drop-us-outer">
        <h3 className="heading-top-line">
          <span><FormattedMessage id="drop.us.outer.drop" defaultMessage="Drop us" /></span><FormattedMessage id="drop.us.outer.line" defaultMessage="a line" />
        </h3>
        {
          this.state.message ?
          <div className="drop-us__message">
            <p>
                {this.state.status}
            </p>
          </div> : <div></div>
        }

        <form action='/contact_mail' method='post' onSubmit={this.submitHandler}>
        <div className="form-styles">
          <div className="row column-3">
            <div class="form-textbox-error-outer">
              <TextBox  placeholder={<FormattedMessage id="drop.us.outer.name" defaultMessage="YOUR NAME" />} name='inputMaterial' onChangeHandler={this.fieldChangeHandler} name_property='name' value={this.state.name} />
              <label className="errorMsg">{this.state.errors.name}</label>
            </div>
            <div class="form-textbox-error-outer">
              <TextBox type="email" placeholder={<FormattedMessage id="drop.us.outer.email" defaultMessage="YOUR EMAIL" />} name='inputMaterial' onChangeHandler={this.fieldChangeHandler} name_property='email' value={this.state.email}/>
              <label className="errorMsg">{this.state.errors.emailid}</label>
            </div>
            <div class="form-textbox-error-outer">
              <TextBox placeholder={<FormattedMessage id="drop.us.outer.phone" defaultMessage="YOUR PHONE " />} name='inputMaterial' onChangeHandler={this.fieldChangeHandler} name_property='mob' value={this.state.mob}/>
              <label className="errorMsg">{this.state.errors.mobileno}</label>
            </div>  
          </div>
          <div className="row column-1">
            <label className="errorMsg">{this.state.errors.matter}</label>
            <TextBox placeholder={<FormattedMessage id="drop.us.outer.help" defaultMessage="HOW CAN WE HELP YOU?" />} name='inputMaterial' onChangeHandler={this.fieldChangeHandler} name_property='matter' value={this.state.matter}/>

            </div>
            <div className="recaptcha form-textbox-error-outer">
          <ReCaptcha
            ref={(el) => {this.captchaDemo = el;}}
            size="normal"
            data-theme="dark"            
            render="explicit"
            sitekey="6LdkRL8UAAAAABDpYVJhNkym095lM1CO40QpShJ7"
            onloadCallback={this.onLoadRecaptcha}
            verifyCallback={this.verifyCallback}
            />
              <label className="errorMsg">{this.state.errors.captchaerror}</label>
            </div>
          <HollowButton onClick={this.submitHandler} btn_text={<FormattedMessage id="footer-links-outer.submit" defaultMessage="Submit" />}/>
          </div>
        </form>
      </div>
    );
  }
}

export default DropUsALine
