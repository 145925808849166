import React, { Component } from 'react';
import FAQBanner from './faq-banner'
import SeamlessPdfExp from '../About/seamless_pdf_exp'
import FaqTab from './faq-tab'

class FAQ extends Component {
  render() {
    return (
      <div className="faq">
      <FAQBanner/>
      <FaqTab/>
      <SeamlessPdfExp/>
      </div>
    );
  }
}

export default FAQ;
