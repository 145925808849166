import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import NewsFeedGridLeft from '../home/news-feed-grid-left'
import PlayStore from '../../assets/images/googleplay_EN.png'
import AppStore from '../../assets/images/Apple_app_store_icon.png'
import MockIphone from '../../assets/images/iPhone X Mockup.png'
class DrinkDonationNewsFeed extends Component {
  render() {
    return (
      <div className="drink-donate__news-feed">
        <NewsFeedGridLeft {...this.props}/>
        <div className="join-community__right">
          <div className="join-community__right-content">
              <div className="mockIphone">
                <img src={MockIphone} alt="MockIphone"/>
              </div>
              <p><FormattedMessage id="join.community.right.content.lorem" defaultMessage="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text" /></p>
              <img className="store-icon" src={AppStore} alt="store-icon"/>
              <img className="store-icon" src={PlayStore} alt="store-icon"/>
              </div>
              </div>
        </div>
    );
  }
}

export default DrinkDonationNewsFeed;
