import React , { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ThemeButton from "../common_components/theme_btn"
import { NavLink } from "react-router-dom"


class DonateNowBtn extends Component {
  render(){
    return(
      <NavLink onClick={this.onClickHandler} to="/payment" className="donate__drop" activeClassName={"active"}>
          <ThemeButton btn_text = {<FormattedMessage id="Donate.a.drop.button" defaultMessage="Donate a drop"/>} />
      </NavLink>
    );
  }
}

export default DonateNowBtn;
