import React , { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import axios from 'axios'
import SocialMediaShare from '../common_components/SocialMediaShare'
import FbIcon from '../../assets/images/facebook.png'
import Insta from '../../assets/images/instagram.png'
import Twitter from '../../assets/images/twitter.png'
import Stripe from '../../assets/images/stripe.png'

import { NavLink } from "react-router-dom"

class Footer extends Component {

  state = {
    message: ''
  }

  onClickHandler = (e) => {
    const email = document.getElementById('subscription_email').value
    const valid = this.validateEmail(email)
    if(valid === true){
      axios.post('/subscribe', {
        email: email
      })
      .then(response => {
        this.setState({
          message: response.data.messages.join(',')
        })
      })
    }else{
      this.setState({
        message: 'Invalid Email Address'
      })
    }
  }

  subscriptionHandler = (e) => {
    const code = e.keyCode || e.which;
    if(code === 13) {
      const email = e.target.value
      const valid = this.validateEmail(email)
      if(valid === true){
        axios.post('/subscribe', {
          email: e.target.value
        })
        .then(response => {
          this.setState({
            message: response.data.messages.join(',')
          })
        })
      }else{
        this.setState({
          message: 'Invalid Email Address'
        })
      }
    }
  }

  validateEmail = (email) => {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false)
    {
      return false;
    }

    return true;
  }

  render () {
    return (
      <div className="footer-outer">
        <div className="footer-content-outer main-container">
          <div className="footer-links-outer">
            <ul>
              <li><NavLink to="/about"><FormattedMessage id="footer-links-outer.about" defaultMessage=" ABOUT" /></NavLink></li>
              <li><NavLink to="/drink-donate"><FormattedMessage id="footer-links-outer.drink" defaultMessage=" DRINK & DONATE" /></NavLink></li>
              <li><NavLink to="/donationwall"><FormattedMessage id="footer-links-outer.donations" defaultMessage=" DONATIONS" /></NavLink></li>
              <li><NavLink to="/news"><FormattedMessage id="footer-links-outer.news" defaultMessage=" NEWS" /></NavLink></li>
              <li><NavLink to="/contact"><FormattedMessage id="footer-links-outer.contact" defaultMessage=" CONTACT US" /></NavLink></li>

            </ul>
          </div>
          <div className="subscribe-outer">
            <h2><FormattedMessage id="subscribe.outer" defaultMessage=" SUBSCRIBE" /></h2>
            <p><FormattedMessage id="subscribe.outer.enter.your" defaultMessage=" Enter your email to get notified about our news" /></p>
            <div className="subscribe__form-wrapper">
              <input type="text" id='subscription_email' onKeyPress={(e) => this.subscriptionHandler(e)} placeholder="Email"/>
              <button onClick={(e) => this.onClickHandler (e)}><FormattedMessage id="footer-links-outer.submit" defaultMessage="Submit" /></button>
            </div>
            <div className="subscription-error-message">{this.state.message}</div>
          </div>
          <div className="footer-bottom-links">
            <NavLink to="/privacy-policy"><FormattedMessage id="footer-bottom-links.privacy" defaultMessage=" Privacy policy" /></NavLink>
            <NavLink to="/terms-conditions"><FormattedMessage id="footer-bottom-links.terms" defaultMessage=" Terms & Conditions" /></NavLink>
            <NavLink to="/faq"><FormattedMessage id="footer-bottom-links.faq" defaultMessage=" FAQ" /></NavLink>
          </div>
          <div className="all-rights-reserved">
            <h3> &copy; <FormattedMessage id="footer-bottom-links.2018" defaultMessage=" 2018 PDF FLY, ALL rights reserved" /></h3>
          </div>
          <div className="social-icons">
            <SocialMediaShare />
          </div>
          <div className="payment-icons">
            <div><img src={Stripe}/></div>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
