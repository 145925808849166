import React , { Component } from 'react';
import axios from 'axios'
import { FormattedMessage } from 'react-intl';
import SelectBox from "../common_components/selectbox"
import DonateNowBtn from "../common_components/donateNowBtn"

class NewsFeedGridLeft extends Component {
  state = {
    items: [{value: null}]
  }

  componentDidMount () {
    axios.get('/packages')

    .then(response => {
    
      if(response.data.length > 0){
        this.setState({
          items: response.data,
          
        })
        this.props.context.packageHanlder(response.data[0].id)       
      }
    })   
  }

  render(){   

    return(
      <div className="news-feed__grid-left">
        <div></div>

        <div>
          <h3 className="heading-top-line">
            <span className="heading-topline-span"><FormattedMessage id="news.feed.grid.left.drink" defaultMessage="DRINK & DONATE" /></span> <FormattedMessage id="news.feed.grid.left.Water" defaultMessage="Water" /><br/><FormattedMessage id="news.feed.grid.left.footprint" defaultMessage="Footprint for individuals" />
          </h3>
          <p><FormattedMessage id="news.feed.grid.left.content" defaultMessage="The schwiizerwasser and züriwasser tastes very good and can be drunk anytime from the tap. In terms of health, they are at least as good as most mineral waters. On the other hand, Hahnenwasser is unbeatably cheap in price: water from the supermarket costs around 1,000 times more than the water supply, which is also delivered to the house. From the Swiss lines flawless drinking water flows, which is available at any time and should also be used consciously as drinking water." /></p>
          <div className="news-feed__grid-dropdown">
            <p><FormattedMessage id="news.feed.grid.left.packages" defaultMessage="Packages" /></p>
            <SelectBox name="donation_offer" packageHanlder={this.props.context.packageHanlder} items={this.state.items} />
          </div>
          <DonateNowBtn onClick={this.clickHandler} />
        </div>
      </div>
    );
  }
}

export default NewsFeedGridLeft;
