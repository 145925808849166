import React, { Component } from 'react'

class Checkbox extends Component{
  render(){
    return(
      <div className="checkbox_outer">
        <input type="checkbox" value={this.props.value} onClick={this.props.onClickHandler} name={this.props.name} id={this.props.id}/>
        <label for={this.props.id}>{this.props.labelname}</label>
      </div>
    );
  }
}

export default Checkbox
