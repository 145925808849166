import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios'
import {AppContext} from '../AppProvider/AppProvider'
import {Redirect} from 'react-router-dom'
import Error from "../../assets/images/close.svg"
import {NavLink} from 'react-router-dom'
import HollowButton from "../common_components/hollow_btn"

class PasswordNew extends Component{

  state={
    message: '',
    email: ''
  }

  resetHandler = (e) => {
    e.preventDefault()
    const valid = this.validateEmail(this.state.email)
    if(valid === true){
      axios.post('/users/password',{
        user: {
          email: this.state.email
        }
      })
      .then(response => {
        this.setState({
          message: response.data.messages.join(',')
        })
      })
    }else{
      this.setState({
        message: 'Invalid Email Address',
      });
    }
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  validateEmail = (email) => {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false)
    {
      return false;
    }

    return true;
  }

  render(){
    return(


      <AppContext.Consumer>
        {(context) => (
          <React.Fragment>
            {context.state.loggedIn == "true" ?  <Redirect to='/' /> : (
            <div className="forget-password">
              <div className="main-container">
              <div className="forget-password__wrapper">
                <div className="forget-password__left">
                    <h1><FormattedMessage id="login__box-btn-set.forgot.your.Password" defaultMessage="Forgot your password ?" /></h1>
                    <div className="form-styles">
                      <form action='/users/password.json' type="post" onSubmit={(e) => this.resetHandler(e)}>

                        <label for="user_email"><FormattedMessage id="login__box-btn-set.Email" defaultMessage="Email" /></label><br />
                          <div className="group">
                            <input autoComplete="off" className="inputMaterial" onChange={(e) => this.onChangeHandler(e)} autocomplete="email" type="email" value={this.state.email} name="email" required/>
                              <span className="highlight"></span>
                              <span className="bar"></span>
                            <label for="user_email"><FormattedMessage id="login__box-btn-set.Email" defaultMessage="Email" /></label>
                          </div>
                          <p>{this.state.message}</p>
                      </form>
                    </div>
                </div>
                <div className="login__box-right">
                  <div className="login__box-right-content">
                        <h1><FormattedMessage id="login__box-btn-se.New" defaultMessage="New to" /> <span><FormattedMessage id="login__box-btn-set.pdf" defaultMessage="PDF FLY?" /></span></h1>
                        <h2><span><FormattedMessage id="login__box-btn-set.Sign.up" defaultMessage="Sign up" /></span> <FormattedMessage id="login__box-btn-set.to.join.the.cause" defaultMessage="to join the cause" /></h2>
                        <NavLink to="/signup"><HollowButton btn_text = "Get started" btn_link = "#"/></NavLink>
                  </div>
                </div>
              </div>

              </div>
            </div>
            )  }

          </React.Fragment>
        )}
      </AppContext.Consumer>
    )
  }
}

export default PasswordNew;
