import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl';
import ThemeButton from "../common_components/theme_btn"
import ImageLoader from "react-loading-image";
import axios from 'axios'

class DonationWallRight extends Component {
  state={
    items:[],
    counter: 0
  }

  apiCallHandler = (counter) => {
    axios.get(`/donations?page_number=${counter}&limit=42`)
    .then(response => {
      this.setState({
        items: [...this.state.items, ...response.data],
        counter
      })
    })
    .catch(function() {
      this.setState({
        items:[],
      })
    });
  }

  onClickHandler = () => {
    let counter = this.state.counter
    this.apiCallHandler(counter + 1)

    const someElement = document.getElementById("donation__right");
    const start = someElement.scrollTop;
    const end = someElement.scrollHeight;
    const change = end - start;
    const increment = 20;
    const duration = 800

    function scrollToBottom() {
      this.animateScroll(duration);
    }

    function easeInOut(currentTime, start, change,duration) {
      currentTime /= duration / 2;
      if (currentTime < 1) {
        return change / 2 * currentTime * currentTime + start;
      }
      currentTime -= 1;
      return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
    }

    function animate(elapsedTime) {
      elapsedTime += increment;
      var position = easeInOut(elapsedTime, start, change, duration);
      someElement.scrollTop = position;
        if (elapsedTime < duration) {
          setTimeout(function() {
            animate(elapsedTime);
          }, increment)
        }
      }
    animate(0);
}
  componentDidMount(){
    this.apiCallHandler(this.state.counter)
  }

  render() {
    const {items} = this.state;
    return (

      <div className="donation-wall__right">
        <div className="donation__right" id="donation__right" >
              {items.map(item => (
                  <div key={item.id} className="donation_right--single-col">
                    <div className="donation__right-img">
                      <ImageLoader
                      src={item.image}
                      loading={() => <div className="img-loader"></div>}
                      error={() => <div className="img-loader--error"></div>}
                      />

                      <div className="donation__right-img-details">
                          <p> {item.first_name} <FormattedMessage id="donation.wall.right.donated" defaultMessage=" donated "/><br/><span>${item.amount}</span> </p>
                      </div>
                    </div>
                  </div>
              ))}
        </div>
        <ThemeButton btn_text = {<FormattedMessage id="donation.wall.right.load.more" defaultMessage=" Load more "/>} onClick={this.onClickHandler}/>
      </div>

    );
  }
}

export default DonationWallRight;
