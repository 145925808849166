import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
class TopBanner_About extends Component{
  render(){
    return(
      <div className="top-banner__wrap">
        <h1> <FormattedMessage id="top.banner.wrap.about" defaultMessage=" About us" /></h1>
      </div>
    );
  }
}

export default TopBanner_About
