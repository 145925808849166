import React , { Component } from 'react';
import TransparentHeader from '../common_components/transparent_header'
import HomeBanner from './banner'
import DonationBlock from './donation'
import JoinCommunity from './join_community'
import BottomGrid from './bottomGrid'
import NewsFeed from './newsFeed'

import HOC from '../HOC/Hoc'


class HomePage extends Component {
  render(){
    return(
      <div className="home-page">
          <HomeBanner {...this.props}/>
          <DonationBlock/>
          <NewsFeed {...this.props}/>
          <JoinCommunity/>
          <BottomGrid/>
      </div>
    );
  }
}
export default HOC(HomePage);
