import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios'
import Slider from "react-slick";
import renderHTML from 'react-render-html';

class DrinkDonationSlider extends Component {
  state={
    items: []
  }

  componentDidMount(){

    axios.get(`drink_donates?language=${this.props.language}`)
    .then(response => {
      this.setState({
        items: response.data
      })
    })

  }

  componentWillReceiveProps(newProps){

    axios.get(`drink_donates?language=${newProps.language}`)
    .then(response => {
      this.setState({
        items: response.data
      })
    })

  }  


  render() {

    const settings1 = {
     
   
      infinite: true,
      slidesToScroll: 1,
      centerPadding: "250px",
      slidesToShow:1,
      speed: 500,
      dots: false,
      arrows: true,
    }

    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      slidesToScroll: 1,
      centerPadding: "250px",
      slidesToShow:1,
      speed: 500,
      dots: false,
      arrows: true,
      responsive: [
    {
      breakpoint: 1200,
      settings: {
        centerPadding: "180px",
        infinite: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        centerPadding: "70px",
         infinite: true,
        centerMode: true,
      }
    },
    {
      breakpoint: 550,
      settings: {
        centerPadding: "50px",
        infinite: true,
        centerMode: true,
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerPadding: "20px",
        infinite: true,
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 2000,
      }
    },
  ]
    };

    return (
      <div className="drink-donate__slider">
        <div className="main-container">
      <h3 className="heading-top-line"> <span class="heading-topline-span"><FormattedMessage id="drink.donate.slider.drink" defaultMessage="DRINK & DONATE" /> </span><FormattedMessage id="drink.donate.slider.concept" defaultMessage="concept" /></h3>
      <p><FormattedMessage id="drink.donate.slider.donate" defaultMessage=" DRINK & DONATE stands for «drinking tap water. Donate drinking water. ». The aim of DRINK & DONATE is to create awareness and fairness. Awareness that we should protect our environment and appreciate our good water and make good use of it. Fairness, that we can make it possible for our fellow human beings, who do not live in such paradisiacal conditions and have no access to clean drinking water, with a small donation." /></p>
      </div>

      {this.state.items.length==1? <div className="drink-slider__wrapper1">
        {console.log("lengths of item" + this.state.items.length)}

      {this.state.items.map((item, key) => (
        <div className="drink-slider" key={key}>
        <div className="drink-slider__container">
        <img src={item.image} className="drink-slider__banner-image"/>
        <img src={item.icon} className="drink-slider__content-image"/>
        <div className="drink-slider__donate-content">
        <h1>{item.title}</h1>
        <p>
          {renderHTML(item.description)}



        </p>
        </div>
        </div>
        </div>
      ))}
   

      </div>  :      <div className="drink-slider__wrapper">
        {console.log("lengths of item" + this.state.items.length)}

      <Slider {...settings}>
      {this.state.items.map((item, key) => (
        <div className="drink-slider" key={key}>
        <div className="drink-slider__container">
        <img src={item.image} className="drink-slider__banner-image"/>
        <img src={item.icon} className="drink-slider__content-image"/>
        <div className="drink-slider__donate-content">
        <h1>{item.title}</h1>
        <p>
          {renderHTML(item.description)}
         
        </p>
        </div>
        </div>
        </div>
      ))}
      </Slider>


      </div>   }

      {/* <div className="drink-slider__wrapper">
        {console.log("lengths of item" + this.state.items.length)}

      <Slider {...settings}>
      {this.state.items.map((item, key) => (
        <div className="drink-slider" key={key}>
        <div className="drink-slider__container">
        <img src={item.image} className="drink-slider__banner-image"/>
        <img src={item.icon} className="drink-slider__content-image"/>
        <div className="drink-slider__donate-content">
        <h1>{item.title}</h1>
        <p>{renderHTML(item.description)}</p>
        </div>
        </div>
        </div>
      ))}
      </Slider>


      </div> */}


      </div>
    );
  }
}

export default DrinkDonationSlider;
