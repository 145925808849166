import React , { Component } from 'react';
import { NavLink } from "react-router-dom"
import { FormattedMessage } from 'react-intl';
import HollowButton from "../common_components/hollow_btn"
import PlayStore from '../../assets/images/googleplay_EN.png'
import AppStore from '../../assets/images/Apple_app_store_icon.png'
import MockIphone from '../../assets/images/iPhone X Mockup.png'


class JoinCommunity extends Component {
  render(){
    return(
      <section className="join-community">
            <div className="join-community__left">
                <div></div>
                <div className="join-community__left-content">
                    <h3 className="heading-top-line">
                    <FormattedMessage id="join.community.left.content.join" defaultMessage="Join the community and be a "/>
                      <br/><FormattedMessage id="join.community.left.content.part" defaultMessage="part of"/> <span className="heading-topline-span"><FormattedMessage id="join.community.left.content.drink" defaultMessage="Drink & Donate"/></span>
                    </h3>
                    <NavLink to="/donationwall"><HollowButton btn_text = {<FormattedMessage id="join.community.left.view.details" defaultMessage="view details "/>} btn_link = "#"/></NavLink>
                </div>
            </div>
            <div className="join-community__right">
              <div className="join-community__right-content">
                  <div className="mockIphone">
                    <img src={MockIphone} alt="MockIphone"/>
                  </div>
                  <p> <FormattedMessage id="join.community.right.content" defaultMessage="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text "/></p>
                  <img className="store-icon" src={AppStore} alt="store-icon"/>
                  <img className="store-icon" src={PlayStore} alt="store-icon"/>
              </div>
              <div></div>
            </div>
            <div className="counter-img"></div>
            <div className="counter-data">
              <div>
                <h3 className="heading-top-line">
                <FormattedMessage id="counter.data.last" defaultMessage="Last year "/>   <span class="heading-topline-span"><FormattedMessage id="counter.data.pdf" defaultMessage="pdf fly "/></span><FormattedMessage id="counter.data.reached" defaultMessage=" reached"/>
                </h3>
                <div className="counter-data__container">
                    <div className="counter-data__single-col">
                        <h5>567,789</h5>
                        <p> <FormattedMessage id="counter.data.people" defaultMessage="people with clean water "/></p>
                    </div>
                    <div className="counter-data__single-col">
                        <h5>897,789</h5>
                        <p> <FormattedMessage id="join.community.left.content.volunteers" defaultMessage="volunteers travelled to africa "/></p>
                    </div>
                    <div className="counter-data__single-col">
                        <h5>6778,758</h5>
                        <p><FormattedMessage id="join.community.left.content.chf" defaultMessage="CHF raised for clean water "/></p>
                    </div>
                </div>
              </div>
              <div></div>

            </div>
      </section>
    );
  }
}

export default JoinCommunity;
