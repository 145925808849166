import React , { Component } from 'react';
import ThemeButton from "../common_components/theme_btn"
import { FormattedMessage } from 'react-intl';
import { NavLink } from "react-router-dom"
import axios from 'axios'
// import TimeAgo from 'react-timeago';
// import frenchStrings from 'react-timeago/lib/language-strings/en'
// import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
 
import TimeAgo from 'timeago-react';

// const formatter = buildFormatter(frenchStrings)
class DonationLeft extends Component {

  state = {
    items: [],
  }

componentDidMount(){
  axios.get('/donations?limit=4')
  .then(response => {
    this.setState({
      items: response.data,
    })
  }).catch(function() {

  });
}
  render(){
    var { items } = this.state;

    return(
          <div className="donation__left" >
              <div></div>
              <div>
                <h3 className="heading-top-line"> <span className="heading-topline-span"><FormattedMessage id="Home.donation.left.thank.you" defaultMessage="Thank you" /> 
                 </span><FormattedMessage id="Home.donation.left.for.making.a" defaultMessage="for making  a "/> <br/><FormattedMessage id="Home.donation.left.big.difference" defaultMessage="big difference" /> </h3>
                <ul>
                  {items.map(item => (
                      <li key={item.id}>
                        <h4>{item.first_name} donated <span className="price-tag"> &euro; {item.amount}</span> to Drink and Donate</h4>
                        <p className="donation__left-date">{item.date} . <TimeAgo datetime={item.created_at} />  </p>
                      </li>
                  ))}
                  
                </ul>
                <NavLink to="/donationwall"><ThemeButton btn_text = {<FormattedMessage id="Home.donation.left.view.more" defaultMessage="view more "/>}/></NavLink>
              </div>
          </div>
    );
    }
}

export default DonationLeft;
