import React, { Component } from 'react';
import { render } from 'react-dom';
import axios from 'axios';

const FaqTabBlock = props => {
  return(
    <div className="faq-block">
    <div className="faq-block__left">
      <img src={props.blockimg} />
        </div>
          <div className="faq-block__right">
            <h3>{props.blocktitle}</h3>
            <p>{props.blockcontent}</p>
          </div>
          <div className="clearfix"></div>
    </div>
  );
};

export default FaqTabBlock;
