import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom'
import DonateNowBtn from "../common_components/donateNowBtn"
import axios from 'axios'


class DonationWallLeft extends Component {

  state={
    items:[],
    counter: 0
  }

  // componentDidMount(){

  //   axios.get('/donations?limit=11')
  //   .then(response => {
  //     this.setState({
  //       items: response.data,
  //     })      
  //   })
  //   .catch(error => {

  //   })
  // }

  render() {
    const {items} = this.state;
    return (

      <div className="donation-wall__left">
        <div className="donation__left" >
            <div></div>
            <div>
              <h3 className="heading-top-line"> <span class="heading-topline-span"> <FormattedMessage id="donation.wall.left.thank" defaultMessage="Thank you " /> </span> <FormattedMessage id="donation.wall.left.making" defaultMessage="for making  a " /> <br/> <FormattedMessage id="donation.wall.left.big" defaultMessage="big difference " /></h3>
              <ul>
                {items.map(item => (
                    <li key={item.id}>
                      <h4>{item.first_name} <FormattedMessage id="donation.wall.left.donated" defaultMessage="donated " /> <span className="price-tag">${item.amount}</span><FormattedMessage id="donation.wall.left.to" defaultMessage="to Drink and Donate " /> </h4>
                      <p className="donation__left-date">{item.date}</p>
                    </li>
                ))}
              </ul>
              <div className="Theme-btn__navi">
                <DonateNowBtn/>
              </div>
            </div>
        </div>
      </div>

    );
  }
}

export default DonationWallLeft;
