import React, { Component } from 'react';
import { render } from 'react-dom';
import { Accordion, AccordionItem } from 'react-light-accordion';
import axios from 'axios';
const FaqTabContent = props => {
  return(
      <div className="faq-content">
        <h3 className="faq-display">{props.blocktitle}</h3>
        <p className="faq-display">{props.blockcontent}</p>
         <Accordion atomic={true}>
            {
              props.qa.map(item => {
                return (
                  <AccordionItem title={item.question}>
                    <p>{item.answer}</p>
                  </AccordionItem>                  
                )                                  
              })
            }
         </Accordion>

    </div>
  );
};

export default FaqTabContent;
