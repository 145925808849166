import React, { Component } from 'react';
import { NavLink, Link } from "react-router-dom"
import axios from 'axios'
import Slider from "react-slick";
import ImageLoader from "react-loading-image";
import { FormattedMessage } from 'react-intl';
class TopStories extends Component {

  state = {
    top_stories: null
  }

  componentDidMount(){
    axios.get(`/stories?category=top_stories&language=${this.props.language}`)
    .then(response => {
      if(typeof(response.data) == 'string'){

      }else{
        this.setState({
          top_stories: response.data
        })
      }
    }).catch(error => {
    });
  }

  componentWillReceiveProps(newProps){
    axios.get(`/stories?category=top_stories&language=${newProps.language}`)
    .then(response => {
      if(typeof(response.data) == 'string'){

      }else{
        this.setState({
          top_stories: response.data
        })
      }
    }).catch(error => {
    });
  }  

  renderNewStories = (item, index) => {

    return (

        <div className="new-stories">
          <div className="new-stories__image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="new-stories__content">
            <h3>{item.title}</h3>
            <p>{item.short_desc}</p>
            <NavLink to={`/news-details/${item.id}`} className="read-more__btn"><FormattedMessage id="Home.read.more" defaultMessage="read more" /></NavLink>
          </div>
        </div>

    );
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    },
  ]
    };
    return (

      <div className="news__landing-block">
          <h1><FormattedMessage id="news__landing-block.top.stories" defaultMessage="Top Stories" /></h1>
          <NavLink to="/news/top_stories" className="news__view-more"><FormattedMessage id="news__landing-block.view.all" defaultMessage="view all" /></NavLink>
          <div className="top-stories">
            {this.state.top_stories && this.state.top_stories.slice(0, 8).map((data, index) =>
              this.renderNewStories(data, index)
            )}
          </div>
          <div className=" top-stories--mob">
          <Slider {...settings}>
              {this.state.top_stories && this.state.top_stories.slice(0, 8).map(item => (
                  <div key={item.id} className="news-single__wrapper">
                    <div className="news-single__content">
                      <div className="img-wrapper">
                      <ImageLoader
                        src={item.image}
                        loading={() => <div className="img-loader"></div>}
                        error={() => <div className="img-loader--error"></div>}
                      />
                      </div>
                      <div className="details">
                          <h6>{item.title}</h6>
                          <p>{(item.short_desc)}</p>
                          <NavLink to={`/news-details/${item.id}`} className="read-more__btn"><FormattedMessage id="Home.read.more" defaultMessage="read more" /></NavLink>
                      </div>
                    </div>
                  </div>
              ))}
          </Slider>
          </div>
      </div>

    );
  }
}

export default TopStories;
