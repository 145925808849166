import React, { Component } from 'react';
class Profile extends Component {
  render(){
    return(
      <div className="profile">
        <img alt='' src={ this.props.image }/>
        <div className="content-outer">
          <h3 className="name">
            <strong>{ this.props.FirstName }</strong> { this.props.LastName }
            <p>( { this. props.designation } )</p>
          </h3>
        </div>
      </div>
    );
  }
}
export default Profile
