import React, { Component } from 'react';
import './assets/scss/main.scss';
import Home from './components/home/home'
import LogIn from './components/Login/index'
import SignUp from './components/Sign-up/index'
import DrinkDonation from './components/Drink-Donation/index'
import FAQ from './components/FAQ/index'
import About from './components/About/index'
import News from './components/News/index'
import NewsCategorySearchPage from './components/News/news-category-searchpage'
import NewsCategory from './components/News/news-category'
import NewsDetailPage from './components/News/news-detail'
import DonationWall from './components/Donation-wall/index.js'
import ThankYou from './components/Thank-you/index.js'
import DonationSearchPage from './components/Donation-wall/donationSearchpage'
import FaqSearchPage from './components/FAQ/faqSearchpage'
import PrivacyPolicy from './components/privacy-policy'
import TermsConditions from './components/terms-conditions'
import Error from './components/common_components/404Page'
import TransparentHeader from './components/common_components/transparent_header'
import ColorHeader from './components/common_components/colorHeader'
import Footer from './components/common_components/footer'
import Successful from './components/Sign-up/successful'
import Functionality from './components/Functionality/index'
import Subscription from './components/Subscription/index'
import Rotate from './components/Rotate/index'
import NewsFullSearch from './components/News/news-full-searchpage'
import PasswordNew from './components/Password/index'
import PasswordReset from './components/Password/PasswordReset'
import ResetSuccess from './components/Password/ResetSuccess'
import ContactUs from './components/contact/contact'

import {AppProvider, AppContext} from './components/AppProvider/AppProvider'

import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import de from "react-intl/locale-data/de";
import fr from "react-intl/locale-data/fr";

import localeData from "./locales/data.json";


import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'

import HOC from './components/HOC/Hoc'
import Payment from './components/Payments/index'

import { loadReCaptcha } from 'react-recaptcha-google';

addLocaleData([...en, ...de, ...fr]);

// const language =
//   (navigator.languages && navigator.languages[0]) ||
//   navigator.language ||
//   navigator.userLanguage;

const language = 'fr'
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

const messages =
  localeData[languageWithoutRegionCode] ||
  localeData[language] ||
  localeData.en;


class App extends Component {
  state = {
    language: 'en'
  }
  componentDidMount() {
    loadReCaptcha();
  }



  changeLanguage = (language) => {
    this.setState({
      language
    })
  }

  render() {
    return (
      <AppProvider>
        <IntlProvider locale={this.state.language} messages={localeData[this.state.language]}>
        <BrowserRouter>
        <ScrollToTop>
            <div className="pdf-fly">
              <Switch>
                <Route path="/login" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/rotate" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />

                <Route path="/compress-pdf" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/pdf-converter" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/word-to-pdf" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/rotate-pdf" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/image-to-pdf" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/pdf-image" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/ppt-to-pdf" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/merge-pdf" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/split-pdf" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />


                <Route path="/privacy-policy" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/terms-conditions" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />

                <Route path="/excel_to_pdf" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />

                <Route path="/news-details/:id" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/payment" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/signup" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/payment/thankyou" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/subscription-status" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/complete-registration" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/password-new" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/users/password/edit" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />
                <Route path="/reset-success" render={(props) => <ColorHeader changeLanguage={this.changeLanguage} language={this.state.language} />} exact />                
                <Route render={(props) => <TransparentHeader changeLanguage={this.changeLanguage} language={this.state.language}/>  } />

              </Switch>

              <Switch>
                <Route path="/" render={(props) => <Home changeLanguage={this.changeLanguage} language={this.state.language} {...props} />} exact />                
                <Route path="/news-search" render={(props) => <NewsFullSearch changeLanguage={this.changeLanguage} language={this.state.language} {...props} />} exact />
                <Route path="/news-category-search" render={(props) => <NewsCategorySearchPage changeLanguage={this.changeLanguage} language={this.state.language} {...props} />} exact />
                <Route path="/compress-pdf" component ={Functionality} exact />
                <Route path="/pdf-converter" component ={Functionality} exact />
                <Route path="/word-to-pdf" component ={Functionality} exact />
                <Route path="/rotate-pdf" component ={Functionality} exact />
                <Route path="/image-to-pdf" component ={Functionality} exact />
                <Route path="/pdf-image" component ={Functionality} exact />
                <Route path="/ppt-to-pdf" component ={Functionality} exact />
                <Route path="/merge-pdf" component ={Functionality} exact />
                <Route path="/split-pdf" component ={Functionality} exact />
                <Route path="/news-details/:id" component ={NewsDetailPage} exact />

                <Route path="/privacy-policy" component ={HOC(PrivacyPolicy)} exact />
                <Route path="/terms-conditions" component ={HOC(TermsConditions)} exact />

                <Route path="/excel_to_pdf" component ={Functionality} exact />

                <Route path="/faqSearchpage" component ={HOC(FaqSearchPage)} exact/>
                <Route path="/donationwallsearch" component ={HOC(DonationSearchPage)} exact/>
                <Route path="/about" render={(props) => <About changeLanguage={this.changeLanguage} language={this.state.language} />} exact/>
                <Route path="/news" render={(props) => <News changeLanguage={this.changeLanguage} language={this.state.language} />} exact/>
                <Route path="/news/:category" render={(props) => <NewsCategory changeLanguage={this.changeLanguage} language={this.state.language} {...props} />} />
                <Route path="/drink-donate" render={(props) => <DrinkDonation changeLanguage={this.changeLanguage} language={this.state.language} />} exact/>

                <Route path="/faq" component ={HOC(FAQ)} exact/>

                <Route path="/contact" component ={HOC(ContactUs)} exact/>

                <Route path="/login" component ={LogIn} exact/>
                <Route exact path="/users/sign_in" render={() => (
                  <Redirect to="/login" />
                )}/>
                <Route path="/payment" component ={HOC(Payment)} exact/>
                <Route exact path="/signup" component={SignUp} exact />
                <Route path="/donationwall" component ={DonationWall} exact />
                <Route path="/payment/thankyou" component ={HOC(ThankYou)} exact />
                <Route path="/subscription-status" component ={HOC(Subscription)} exact />
                <Route path="/complete-registration" component ={Successful} exact />
                <Route path="/password-new" component ={HOC(PasswordNew)} exact />
                <Route path="/users/password/edit" component ={HOC(PasswordReset)} exact />
                <Route path="/reset-success" component ={HOC(ResetSuccess)} exact />
                <Route path="/rotate" component ={Rotate} exact />

                <Route component ={Error}/>
              </Switch>

              <Footer/>
            </div>
          </ScrollToTop>
        </BrowserRouter>
        </IntlProvider>
      </AppProvider>
    );
  }
}

export default App;
