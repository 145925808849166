import React, { Component } from 'react';
import {AppContext} from '../AppProvider/AppProvider'

const HOC = (Component) => {
  return (props) => (
      <AppContext.Consumer>
           {context =>  <Component {...props} context={context} />}
      </AppContext.Consumer>
  )
}

export default HOC;