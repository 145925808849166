import React from 'react'
import { FormattedMessage } from 'react-intl';
import ErrorImg from '../../assets/images/error-404.svg'

const Error = () => {
  return(
    <div className="page-404">
        <div className="main-container">
          <img alt='404 page not found' src={ErrorImg}/>
          <h1><FormattedMessage id="page-404.not.found" defaultMessage=" Page Not Found" /></h1>
          <h2><FormattedMessage id="page-404.we.are.sorry" defaultMessage=" We are sorry but the page you are looking does not exist." /></h2>
        </div>
    </div>
  );
}

export default Error;
