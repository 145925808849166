import React, { Component } from 'react'

class TextBox extends Component{
  render(){
    return(
      <div className="input-text-outer">
        { this.props.name_property === 'amount' ? (
          <input type="text" value={this.props.value} name={this.props.name_property} onChange={(e) => this.props.onChangeHandler(e,this.props.name_property)} className = {this.props.name} placeholder = {this.props.placeholder}/>
        ) : (
          <input type="text" name={this.props.name_property} className = {this.props.name} placeholder = {this.props.placeholder}/>
        ) }

      </div>
    );
  }
}

export default TextBox
