import React, { Component } from 'react';
import TopBanner_About from './top-banner.js';
import ContentBlock2 from './content_block2.js';
import MadeA_Diff from './made_a_diff.js';
import NewsFeed from '../home/newsFeed.js'
import SeamlessPdfExp from './seamless_pdf_exp.js';
import HOC from '../HOC/Hoc'


class About extends Component {
  render() {
    return (
      <div className="about-outer-container">
        <TopBanner_About/>
        <div className="main-container">
          <ContentBlock2/>
          <MadeA_Diff {...this.props}/>
        </div>
        <NewsFeed {...this.props}/>
        <SeamlessPdfExp/>
      </div>

    );
  }
}

export default HOC(About);
