import React, { Component } from 'react';
import Icon from '../../../assets/images/compresspdf.png'
import Options from './Options';
import SelectPages from './SelectPages';

class SplitPDF extends Component {

    state = {
        option : null
    }

    optionChoosed = (choosedOption) => {
        this.setState({
            option: choosedOption
        })

        if (choosedOption === 1) {
            this.props.splitDownloadChooser()
        }        
    }

    render() {
        return (
           <div>
               {this.state.option == null && <Options choosedOption = {this.optionChoosed}/>}              
               {this.state.option == 2 && <SelectPages onUploadCompleteHandler={this.props.onUploadCompleteHandler} updateCounter={this.props.updateCounter} chosenFile={this.props.chosenFile}/>}
           </div>
        );
    }
}

export default SplitPDF;