import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import NewsModal from './NewsModal';
import { NavLink } from "react-router-dom"
import DonateNowBtn from "../common_components/donateNowBtn"
import NewStories from "./new-stories"
import EditorsPicks from './editors-picks'
import TopStories from './top-stories'
import BottomBanner from './bottom-banner'
import HOC from '../HOC/Hoc'

class News extends Component {
  state = {
    inputValue : ""
  }

  onChangeHandler = e => {
    this.setState({inputValue : e.target.value})
  }

  enterPressed(event, link) {
    var code = event.keyCode || event.which;
    if(code === 13) {
      document.getElementById('donation-wall-search_bar').click()
    }
  }

  render() {
    const link = `/news-search?keyword=${this.state.inputValue}`
    return (

      <div className="news">
        <NewsModal />
          <div className="news__banner">
            <div className="donation-wall__search-bar">
              <div className="donation-wall__search-bar--content">
                <input type="text" placeholder="Search" onChange={this.onChangeHandler} onKeyPress={(e) => this.enterPressed(e, link)}/>
                <NavLink id='donation-wall-search_bar'  to={link} className="search-btn"/>
              </div>
            </div>
            <div className="news__banner-content">
                <h1 className="heading-top-line"> <span class="heading-topline-span"><FormattedMessage id="news__banner.lorem" defaultMessage=" Lorem Ipsum" /> </span><FormattedMessage id="news__banner.is" defaultMessage=" is simply dummy text" /></h1>
                <p><FormattedMessage id="news__banner.of.type" defaultMessage=" of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem " /></p>
                <DonateNowBtn/>
            </div>
          </div>
          <div className="main-container">
            <NewStories {...this.props}/>
            <EditorsPicks {...this.props}/>
            <TopStories {...this.props}/>
            <BottomBanner/>
          </div>

      </div>
    );
  }
}

export default HOC(News);
