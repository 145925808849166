import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios'
import QueryString from 'query-string'
import {AppContext} from '../AppProvider/AppProvider'
import {Redirect} from 'react-router-dom'
import ThemeButton from "../common_components/theme_btn"
import {NavLink} from 'react-router-dom'
import HollowButton from "../common_components/hollow_btn"
class PasswordReset extends Component{

  state={
    message: '',
    reset_password_token: '',
    password: '',
    password_confirmation: ''
  }


  componentDidMount(){
    const parsed = QueryString.parse(this.props.location.search);
    this.setState({reset_password_token : parsed.reset_password_token})
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  resetHandler = (e) => {
    e.preventDefault()
    const email = e.target.value
    axios.put('/users/password',{
      user: {
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
        reset_password_token: this.state.reset_password_token
      }
    })
    .then(response => {
      if(response.data.status === 'success') {
        this.props.history.push("/reset-success");
      }else{
        document.getElementById('change-password').disabled = false
        this.setState({
          message: response.data.messages.join(',')
        })
      }
    })
  }

  render(){
    return(

      <AppContext.Consumer>
        {(context) => (
          <React.Fragment>
            {context.state.loggedIn == "true" ?  <Redirect to='/' /> : (
              <div className="forget-password forget-password--change">
              <div className="main-container">
              <div className="forget-password__wrapper">
                <div className="forget-password__left">
                      <h1><FormattedMessage id="login__box-btn-set.forgot.your.Password" defaultMessage="Forgot your password ?" /></h1>
                      <div className="form-styles">
                      <form action="/users/password" accept-charset="UTF-8" method="post" onSubmit={this.resetHandler}>
                      <input name="utf8" type="hidden" value="&#x2713;" />
                      <input type="hidden" name="_method" value="put" />

                        <input type="hidden" value={this.state.reset_password_token} name="user[reset_password_token]" id="user_reset_password_token" />
                        <div className="group">
                          <input autoComplete="off" className="inputMaterial" onChange={e => this.onChangeHandler(e)} value={this.state.email}  type="password" name="password" required/>
                            <span className="highlight"></span>
                            <span className="bar"></span>
                          <label for="user_password"><FormattedMessage id="login__box-btn-set.new.Password" defaultMessage="New password" /></label>
                        </div>
                        <div className="group">
                          <input autoComplete="off" className="inputMaterial" onChange={e => this.onChangeHandler(e)}  type="password" name="password_confirmation" required/>
                            <span className="highlight"></span>
                            <span className="bar"></span>
                          <label for="user_password_confirmation"><FormattedMessage id="login__box-btn-set.confirm.new.Password" defaultMessage="Confirm new password" /></label>
                          
                        </div>

                        <div className="actions">
                          <input className="theme-btn" id="change-password" type="submit" name="commit" value="Change my password" data-disable-with="Change my password" />
                        </div>
                        <div>
                          <p>{this.state.message}</p>
                        </div>
                      </form>
                    </div>
                </div>
                <div className="login__box-right">
                <div className="login__box-right-content">
                      <h1><FormattedMessage id="login__box-btn-se.New" defaultMessage="New to" /> <span><FormattedMessage id="login__box-btn-set.pdf" defaultMessage="PDF FLY?" /></span></h1>
                      <h2><span><FormattedMessage id="login__box-btn-set.Sign.up" defaultMessage="Sign up" /></span> <FormattedMessage id="login__box-btn-set.to.join.the.cause" defaultMessage="to join the cause" /></h2>
                      <NavLink to="/signup"><HollowButton btn_text = "Get started" btn_link = "#"/></NavLink>
                </div>
                </div>
              </div>

              </div>
            </div>
            )  }

          </React.Fragment>
        )}
      </AppContext.Consumer>
    )
  }
}

export default PasswordReset;
