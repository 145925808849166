import React, { Component  } from 'react';
import { FormattedMessage } from 'react-intl';
import DonateNowBtn from "../common_components/donateNowBtn"

class BottomBanner extends Component {
  render(){
    return(
      <div className="BottomBanner-outer">
          <div></div>
          <div className="text-content-outer">
            <h3 className="heading-bold"><strong><FormattedMessage id="BottomBanner-cape" defaultMessage="Cape Town," /></strong> <FormattedMessage id="BottomBanner-there" defaultMessage="there is only one topic of" /><br/><FormattedMessage id="BottomBanner-Conversation" defaultMessage="Conversation:" /> <strong><FormattedMessage id="news.feed.grid.left.Water" defaultMessage="Water" /></strong></h3>
            <DonateNowBtn/>
          </div>
      </div>
    );
  }
}

export default BottomBanner;
