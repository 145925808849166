import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, Link } from "react-router-dom"
import Sad from '../../assets/images/sad.svg'
import renderHTML from 'react-render-html';

import ImageLoader from "react-loading-image";
import DonateNowBtn from "../common_components/donateNowBtn"


import { LazyLoadComponent } from 'react-lazy-load-image-component';


import axios from 'axios'
import QueryString from 'query-string'

import DonationWallBanner from '../../assets/images/donationwall-banner.jpg'
import HOC from '../HOC/Hoc'

class NewsFullSearch extends Component {

  state = {
    inputValue : "" ,
    items: null
  }

  componentDidMount(){
    const parsed = QueryString.parse(this.props.location.search);
    this.setState({inputValue : parsed.keyword})
    axios.get(`/stories?keyword=${parsed.keyword}&language=${this.props.language}`)
    .then(response => {
      this.setState({
        items: response.data,
      })
    }).catch(function() {

    });
  }

  componentWillReceiveProps(newProps){
    const parsed = QueryString.parse(this.props.location.search);
    this.setState({inputValue : parsed.keyword})
    axios.get(`/stories?keyword=${parsed.keyword}&language=${newProps.language}`)
    .then(response => {
      this.setState({
        items: response.data,
      })
    }).catch(function() {

    });
  }  

  onChangeHandler = e => {
    this.setState({inputValue : e.target.value})
    axios.get(`/stories?keyword=${e.target.value}&language=${this.props.language}`)
    .then(response => {
      this.setState({
        items: response.data
      })
    }).catch(function() {

    });
  }

  renderNames = item => {
    const { inputValue } = this.state;
        return    <LazyLoadComponent>
                        <div className="newspage-single-news">
                          <div className="img-wrapper">
                          <ImageLoader
                          src={item.image}
                          loading={() => <div className="img-loader"></div>}
                          error={() => <div className="img-loader--error"></div>}
                          />
                          </div>
                          <div className="details">
                          <h6>{item.title}</h6>
                          <p>{renderHTML(item.short_desc)}</p>
                          <NavLink to={`/news-details/${item.id}`} className="read-more__btn"><FormattedMessage id="Home.read.more" defaultMessage="read more" /></NavLink>
                          </div>
                        </div>
              </LazyLoadComponent>
  }

  render() {
    const { inputValue, items } = this.state;

    return (
      <div className="news-fullSearch-page">
          <div className="news__banner">
            <div className="donation-wall__search-bar">
              <div className="donation-wall__search-bar--content">
                <input type="text" placeholder="Search people" value={inputValue} onChange={this.onChangeHandler}/>
                <div className="search-btn"> </div>
              </div>
            </div>
            <div className="news__banner-content">
                <h1 className="heading-top-line"> <span class="heading-topline-span"><FormattedMessage id="news-fullSearch-page.lorem" defaultMessage="Lorem Ipsum " /></span><FormattedMessage id="news-fullSearch-page.lorem.is.simply" defaultMessage="is simply dummy text " /></h1>
                <p><FormattedMessage id="news-fullSearch-page.lorem.of" defaultMessage="of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem  " /></p>
                <DonateNowBtn/>
            </div>
          </div>
          <div className="news-search__result-block">
              <div className="main-container">
                <h3><FormattedMessage id="donation.search.result.block.search" defaultMessage="Search result for " /> <span> { inputValue } </span> " </h3>
                <div className="news-search__result-block-container">
                    {
                      items ? (
                        items.map( item => {
                          return this.renderNames(item)
                        })
                      ) : (
                        <div></div>
                      )
                    }
                </div>
                {
                  items ? (
                    items.length == 0 ? <div className="donation-search__not-found">
                    <img src={Sad} />
                    <h1><FormattedMessage id="donation.search.result.block.nothing" defaultMessage="Nothing Found " /></h1>
                    <p><FormattedMessage id="donation.search.result.block.sorry" defaultMessage="Sorry, but nothing matched your search terms. Please try again with some different keywords " /> </p>
                    </div> : <div></div>
                  ) : (
                    <div></div>
                  )
                }
              </div>
            </div>
      </div>

    );
  }
}

export default HOC(NewsFullSearch);
