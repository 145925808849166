import React, { Component } from 'react';
import { Document, Page } from 'react-pdf/dist/entry.noworker';
import axios from 'axios';

class SelectPages extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
        pages: [],
        disabled: true
    }

    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    }

    onLoadError = (error) => {
        alert('Error while loading document! ' + error.message)
    }

    splitPdfHandler = () => {
        this.props.onUploadCompleteHandler()
        // axios({
        //     url: '/split_merge_pdfs',
        //     method: 'POST',
        //     responseType: 'blob', // important
        //     data: {
        //         pages: this.state.pages,
        //         file_names: sessionStorage.getItem('file_names')
        //     }
        //   }).then((response) => {
        //     const url = window.URL.createObjectURL(new Blob([response.data]));
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', 'merged.pdf');
        //     document.body.appendChild(link);
        //     link.click();
        //   });

        axios({
            url: '/split_merge_pdfs',
            method: 'POST',
            data: {
                pages: this.state.pages,
                file_names: sessionStorage.getItem('file_names')
            }
          }).then((response) => {
            if(response.data.status === 'success'){
                sessionStorage.setItem("out_put_file_name", response.data.out_put_file_name);
                sessionStorage.setItem("file_name", "merged.pdf");
                this.props.updateCounter(100)
            }else{
                alert('something went wrong, try again')
                window.location.reload();
            }
          });

    }

    onItemClickhandler = (e) => {
      let pageNumber = e.target.closest('div').getAttribute('data-page-number');
      if(e.target.parentNode.classList.contains('active')){
            e.target.parentNode.classList.remove("active");
            let prevPages = [...this.state.pages]
            let index = prevPages.indexOf(pageNumber)
            if(index > -1) {
                prevPages.splice(index, 1)
            }
            let disabled;
            if(prevPages.length > 1){
                disabled = false
            }else{
                disabled = true
            }
            this.setState({
                pages: prevPages,
                disabled
            })
      }else{
            e.target.parentNode.classList.add("active");
            let newPages = [...this.state.pages, pageNumber]
            let disabled;
            if(newPages.length > 1){
                disabled = false
            }else{
                disabled = true
            }
            this.setState({
                pages: newPages,
                disabled
            })
      }
    }

    renderLoader = () => {
      return(
        <div>
            <ul className="loading-pdf-msg">
              <li>Please wait...</li>
            </ul>
        </div>
      )
    }

    render() {
        const { pageNumber, numPages } = this.state;

        let rows = [];
        for (var i = 1; i <= numPages; i++) {
            rows.push(<Page pageNumber={i} key={i} width={200} onClick={(e) => this.onItemClickhandler(e)} className='height-canvas'  renderAnnotationLayer={false} renderTextLayer={false} />);
        }
        return (
            <div className="split-pdf-container">
              <div className="main-container">
                <Document
                  file={this.props.chosenFile}
                  onLoadSuccess={this.onDocumentLoad}
                  onLoadError={this.onLoadError}
                  className='document-class'
                  loading={this.renderLoader()}
                >
                <div className="instruction">
                    Click on the pages you want to select.
                </div>
                  <div className="page-preview-container">
                    {rows}
                  </div>
                </Document>
              </div>
              <button disabled={this.state.disabled} onClick={this.splitPdfHandler}>Split PDF</button>
          </div>
        );
    }
}

export default SelectPages;
