import React, { Component } from 'react'

import CompressPdf from './file-upload/Compress-pdf'
import PdfConverter from './file-upload/Pdf-converter'
import WordToPdf from './file-upload/word-pdf'
import RotatePdf from './file-upload/rotatePdf'
import ImageToPdf from './file-upload/image-pdf'
import PdfToImage from './file-upload/Pdf-image'
import PptToPdf from './file-upload/Ppt-pdf'
import MergePdf from './file-upload/Merge-pdf'
import ExcelToPdf from './file-upload/excelToPdf'
import SplitPdf from './file-upload/SplitPdf'

import { Route, Switch } from 'react-router-dom'

class FileUploadChooser extends Component {

  render() {
    return (
      <div className="inner-page__file-upload">
        <div className="main-container">
          <div className="inner-page__process">
          <Switch>
            <Route path="/compress-pdf" render={(props) => <CompressPdf {...props} onUploadCompleteHandler={this.props.onUploadCompleteHandler} updateCounter={ this.props.updateCounter } />  } exact />
            <Route path="/pdf-converter" render={(props) => <PdfConverter {...props} onUploadCompleteHandler={this.props.onUploadCompleteHandler} updateCounter={ this.props.updateCounter } />  } exact />
            <Route path="/word-to-pdf" render={(props) => <WordToPdf {...props} onUploadCompleteHandler={this.props.onUploadCompleteHandler} updateCounter={ this.props.updateCounter } />  } exact />  
            <Route path="/excel_to_pdf" render={(props) => <ExcelToPdf {...props} onUploadCompleteHandler={this.props.onUploadCompleteHandler} updateCounter={ this.props.updateCounter } />  } exact />                       
            <Route path="/rotate-pdf" component ={RotatePdf} exact />
            <Route path="/image-to-pdf" render={(props) => <ImageToPdf {...props} onUploadCompleteHandler={this.props.onUploadCompleteHandler} updateCounter={ this.props.updateCounter } />  } exact />             
            <Route path="/pdf-image" component ={PdfToImage} exact />
            <Route path="/ppt-to-pdf" render={(props) => <PptToPdf {...props} onUploadCompleteHandler={this.props.onUploadCompleteHandler} updateCounter={ this.props.updateCounter } />  } exact /> 
            <Route path="/merge-pdf" render={(props) => <MergePdf {...props} onUploadCompleteHandler={this.props.onUploadCompleteHandler} updateCounter={ this.props.updateCounter }
            onUploadCompleteHandlerMerge={this.props.onUploadCompleteHandlerMerge}
            updateCounterMerge = { this.props.updateCounterMerge } 
            chosenFile = {this.props.chosenFile}            
            />  } exact />
            <Route path="/split-pdf" render={(props) => <SplitPdf {...props} onUploadCompleteHandler={this.props.onUploadCompleteHandler} updateCounter={ this.props.updateCounter } onUploadCompleteHandlerSplit={this.props.onUploadCompleteHandlerSplit}
            updateCounterSplit = { this.props.updateCounterSplit } 
            chosenFile = {this.props.chosenFile}
            />  } exact />            
          </Switch>
        </div>
      </div>
    </div>
    );
  }
}

export default FileUploadChooser;
