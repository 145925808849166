import React , { Component } from 'react';

class SingleColGrid extends Component {
  render(){
    return(
      <div className="single-col">
        <img alt='' src= { this.props.col_img }/>
        <h6>{ this.props.col_txt }</h6>
      </div>
    );
  }
}
export default SingleColGrid;
