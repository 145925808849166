import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import DonateNowBtn from "../../common_components/donateNowBtn"
import FileDownload from 'js-file-download'
import DropboxSaver from '../../DropboxSaver/DropboxSaver'
import GoogleDriveSaver from '../../GoogleDriveSaver/GoogleDriveSaver'

import SingleColGrid from '../../home/single-col-banner'
import { NavLink } from 'react-router-dom'
import axios from 'axios'

import Icon from '../../../assets/images/compresspdf.png'

import Icon1 from '../../../assets/images/rotate.png'
import Icon2 from '../../../assets/images/pdftoexcel.png'
import Icon3 from '../../../assets/images/pdf_to_word.png'
import Icon4 from '../../../assets/images/pdftojpg.png'

class imgToPdfDownload extends Component {

  downLoadFile = () => {
    axios.post('/download_file', {
      out_put_file_name: sessionStorage.getItem("out_put_file_name"),
      file_name: sessionStorage.getItem("file_name")
    }).then(response => {
      FileDownload(response.data, sessionStorage.getItem("file_name"));
    })
  }

  render() { 
    const out_put_file_name = sessionStorage.getItem("out_put_file_name")
    const file_name = sessionStorage.getItem("file_name")     
    return (
      <div className="inner-page__file-download-wrapper">
            <div className="file-download__top-row">
                <div className="file-download-top__details">
                    <p className="file-download__sucess-txt"><FormattedMessage id="file-download__sucess-txt.we" defaultMessage="We have successfully" /> <span><FormattedMessage id="file-download__sucess-txt.converted" defaultMessage="converted your  document" />  </span></p>
                    <div className="cloud-widget desktop">
                      <p><FormattedMessage id="file-download__sucess-txt.save" defaultMessage="Save to" /></p>
                      <div className="cloud-widget__wrap">
                        <GoogleDriveSaver url={`${window.location.origin}/download_file?out_put_file_name=${out_put_file_name}&file_name=${file_name}`} file_name={file_name} />  
                          <DropboxSaver url={`${window.location.origin}/download_file?out_put_file_name=${out_put_file_name}&file_name=${file_name}`} file_name={file_name} />
                      </div>
                    </div>
                </div>
                <div className="file-download-top__right">
                    <h2><FormattedMessage id="file-download__sucess-txt.lorem" defaultMessage="lorem ipsum is a simple" /><br/><span><FormattedMessage id="file-download__sucess-txt.dummy" defaultMessage="dummy text" /></span></h2>
                    <DonateNowBtn/>
                </div>
            </div>
            <div className="file-download__bottom-row">
              <div className="file-download__bottom-btn">
                  <img src={Icon} alt="Icon"/>
                  <div className="file-download__bottom-filename">
                    <h2>{sessionStorage.getItem("file_name")}</h2>
                    <p><FormattedMessage id="file-download__sucess-txt.document" defaultMessage="Document sucessfully converted" /></p>
                  </div>
                  <div className="download-btn">
                    <form action="/download_file" method="post">
                      <input type='hidden' name='file_name' value={sessionStorage.getItem("file_name")} />
                      <input type='hidden' name='out_put_file_name' value={sessionStorage.getItem("out_put_file_name")} />
                      <input type="submit" value="Download File" />
                    </form>
                  </div>
                  <div className="cloud-widget mobile">
                    <div className="cloud-widget__wrap">
                     <GoogleDriveSaver url={`${window.location.origin}/download_file?out_put_file_name=${out_put_file_name}&file_name=${file_name}`} file_name={file_name} />  
                        <DropboxSaver url={`${window.location.origin}/download_file?out_put_file_name=${out_put_file_name}&file_name=${file_name}`} file_name={file_name} />
                    </div>
                  </div>
              </div>
              <div className="file-download__bottom-options">
                      <NavLink to="/compress-pdf"><SingleColGrid col_txt = "Rotate" col_img = {Icon1}/></NavLink>
                      <NavLink to="/compress-pdf"><SingleColGrid col_txt = "to Excel" col_img = {Icon2}/></NavLink>
                      <NavLink to="/compress-pdf"><SingleColGrid col_txt = "to Word" col_img = {Icon3}/></NavLink>
                      <NavLink to="/compress-pdf"><SingleColGrid col_txt = "to JPEG" col_img = {Icon4}/></NavLink>
              </div>
            </div>
      </div>
    );
  }
}

export default imgToPdfDownload;
