import React , { Component } from 'react';
import Slider from "react-slick";
import axios from 'axios'
import renderHTML from 'react-render-html';
import { FormattedMessage } from 'react-intl';
import ImageLoader from "react-loading-image";
import { NavLink } from "react-router-dom"

class NewsSlider extends Component {


state = {
  items: []
}

componentDidMount(){

  axios.get(`/stories?language=${this.props.language}`)
  .then(response => {
    this.setState({
      items: response.data,
    })
  })
  .catch(error => {

  })
}

componentWillReceiveProps(newProps){
  axios.get(`/stories?language=${newProps.language}`)
  .then(response => {
    this.setState({
      items: response.data
    })
  })    
}

number = () => {
  const num = this.state.items.length
  if(num == 1){
    return 1
  } else if (num == 2) {
    return 2
  } else {
    return 3
  }
}

  render() {
    const { items } = this.state;
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: this.number(),
      slidesToScroll: 1,
      responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    },
  ]
    };
    return (
      <div className="">
        <Slider {...settings}>
            {items.map(item => (
                <div key={item.id} className="news-single__wrapper">
                  <div className="news-single__content">
                    <div className="img-wrapper">
                    <ImageLoader
                      src={item.image}
                      loading={() => <div className="img-loader"></div>}
                      error={() => <div className="img-loader--error"></div>}
                    />
                    </div>
                    <div className="details">
                        <h6>{item.title}</h6>
                        <p>{(item.short_desc)}</p>
                        <NavLink to={`/news-details/${item.id}`} className="read-more__btn">{<FormattedMessage id="Home.read.more" defaultMessage="read more "/>}</NavLink>
                    </div>
                  </div>
                </div>
            ))}
        </Slider>
      </div>
    );
  }
}

export default NewsSlider;
