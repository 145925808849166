import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ImgBanner from '../assets/images/privacy-policy-banner.jpg'
import renderHTML from 'react-render-html';
import axios from 'axios'

class PrivacyPolicy extends Component {

    state = {
        policy: ''
    }

    componentDidMount(){
        axios.get('/policy')
        .then(response => {
            this.setState({
                policy: response.data.description
            })
        })
    }

    render(){
        return (
            <div className="privacy-policy">
              <div className="privacy-policy__background">
                  <img src={ImgBanner}/>
                </div>
                    <div className="privacy-policy__container main-container">
                    <h3 className="heading-top-line"><FormattedMessage id="privacy-policy__background" defaultMessage="Privacy Policy" /></h3>
                        <div className="privacy-policy__content">
                      <p>
                          {renderHTML(this.state.policy)}
                      </p>
                </div>
                </div>
            </div>
          )        
    }
}

export default PrivacyPolicy;
