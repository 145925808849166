import React , { Component } from 'react'
import { FormattedMessage } from 'react-intl';
class LocationMap extends Component {
  render(){
    return(
      <div className="location-outer">
        <div className="address-wrap-outer">
          <div className="address-wrap">
            <h2><FormattedMessage id="location.outer.new" defaultMessage="New corporate office" /></h2>
            <p>
            <FormattedMessage id="drop.us.outer.1" defaultMessage="Non-profit organiztaion" /><br/>
            <FormattedMessage id="drop.us.outer.2" defaultMessage="Landenbergstrasse 19" /><br/>
            <FormattedMessage id="drop.us.outer.3" defaultMessage="8037 Zurich" />
            </p>
          </div>
          <div className="address-wrap">
            <h2><FormattedMessage id="address.wrap.new" defaultMessage="New corporate office" /></h2>
            <p>
            <FormattedMessage id="address.wrap.3" defaultMessage=" " />
            <FormattedMessage id="address.wrap.1" defaultMessage="Non-profit organiztaion" /><br/>
            <FormattedMessage id="address.wrap.2" defaultMessage="Landenbergstrasse 19" /><br/>
            </p>
          </div>
        </div>  
        <div className="map-outer">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2700.976541644831!2d8.526129550932898!3d47.39289057906881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900a6967f0f3e7%3A0x9d50957467966d57!2sLandenbergstrasse+19%2C+8037+Z%C3%BCrich%2C+Switzerland!5e0!3m2!1sen!2sin!4v1544099140122" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    );
  }
}

export default LocationMap
