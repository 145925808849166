import React, { Component } from 'react'
import TextBox from '../common_components/text_box'

class LabelInputPair2Column extends Component{
  render(){
    return(
      <div className="label-input-pair-row column-2">
        <div className="label">
          {this.props.label}
        </div>
        <TextBox name={this.props.name} name_property={this.props.name_property} placeholder={this.props.placeholder}/>
      </div>
    );
  }
}
export default LabelInputPair2Column
