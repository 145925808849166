import React, { Component } from 'react'

import Drive from '../../assets/images/drive.png'

const GOOGLE_DRIVE_URL = 'https://apis.google.com/js/platform.js';
let scriptLoadingStarted = false;

class GoogleDriveSaver extends Component {

  constructor(props) {
    super(props);
    this._createScript = this.createScript.bind(this);
  }

  createScript() {
    if (!scriptLoadingStarted) {
      scriptLoadingStarted = true;
      const script = document.createElement("script");
      script.src = GOOGLE_DRIVE_URL;
      script.async = true;

      document.body.appendChild(script);
    }
  }

  componentWillMount() {
    this._createScript()
  }

  onClickHandler = () => {
    document.getElementById('___savetodrive_0').click();
  }


  render(){
    return(
      <div className="g-savetodrive__wrapper">
        <div class="g-savetodrive"
            data-src={this.props.url}
            data-filename={this.props.file_name}
            data-sitename="PDF FLY">
        </div>
        <label class="mobile"><span>Save to Drive</span></label>
        <img src={Drive} alt="cloud-widget-drive" onClick={this.onClickHandler}/>
      </div>
    )
  }


}

export default GoogleDriveSaver
