import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Accordion, AccordionItem } from 'react-light-accordion';
import axios from 'axios'
import PlayStore from '../../assets/images/googleplay_EN.png'
import AppStore from '../../assets/images/Apple_app_store_icon.png'
import MockIphone from '../../assets/images/iPhone X Mockup.png'
import 'react-light-accordion/demo/css/index.css';

class paymentRight extends Component{

  state = {
    queries: []
  }

  componentDidMount(){  
    axios.get('/queries')
    .then(response => {
      this.setState({
        queries: response.data
      })
    })
  }

  render(){
    const queries = this.state.queries.map(query => {
      return(
        <AccordionItem title={query.question}>
          <p>{query.answer}</p>
        </AccordionItem>
      )
    })
    return(
      <div className="payment__Right">
        <div className="questions_container">
          <h3><FormattedMessage id="questions_container.questains" defaultMessage="Questions" /></h3>
          <Accordion atomic={true}>
            {queries}
          </Accordion>
        </div>
        <div className="join-community__right">
          <div className="join-community__right-content">
              <div className="mockIphone">
                <img src={MockIphone} alt="MockIphone"/>
              </div>
              <p><FormattedMessage id="questions_container.lorem" defaultMessage="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text" /></p>
              <div className="store-icons-container">
                <img className="store-icon" src={AppStore} alt="store-icon"/>
                <img className="store-icon" src={PlayStore} alt="store-icon"/>
              </div>
          </div>
        </div>
      </div>
    );
  }

}
export default paymentRight;
