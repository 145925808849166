import React, { Component } from 'react';
import { NavLink, Link } from "react-router-dom"

import DonationWallRight from './donationWallRight'
import DonationWallLeft from './donationwallLeft'
import DonationWallBanner from '../../assets/images/donationwall-banner.jpg'

class DonationWall extends Component {

  state = {
    inputValue : ""
  }

  onChangeHandler = e => {   
    this.setState({inputValue : e.target.value})
  }

  enterPressed(event, link) {
    var code = event.keyCode || event.which;
    if(code === 13) {
      document.getElementById('donation-wall-search_bar').click()
    }
  }

  render() {
    const link = `/donationwallsearch?keyword=${this.state.inputValue}`
    return (
      <div className="donation-wall">
          <div className="donation-wall__banner">
              <img src={DonationWallBanner} alt="donation-wall__banner"/>
              <div className="donation-wall__search-bar">
                <div className="donation-wall__search-bar--content">
                  <input type="text" placeholder="Search people" onChange={this.onChangeHandler} onKeyPress={(e) => this.enterPressed(e, link)}/>
                  <NavLink id='donation-wall-search_bar'  to={link} className="search-btn"/>
                </div>
              </div>
          </div>
          <DonationWallLeft/>
          <DonationWallRight/>
      </div>

    );
  }
}

export default DonationWall;
